<template>
  <div id="tariffs" class="tariffs">
    <h2>Тарифы</h2>
    <div class="slider" v-if="filteredTariffs.length && status === 'success'">
      <swiper
        :modules="modules"
        :slides-per-view="'auto'"
        :slidesOffsetBefore="16"
        :space-between="16"
        navigation
        :pagination="breakpointsMobile.includes(screenSize)"
        :direction="
          breakpointsMobile.includes(screenSize) ? 'horizontal' : 'vertical'
        "
        :allow-touch-move="
          breakpointsMobile.includes(screenSize) ? true : false
        "
        :touch-move-stop-propagation="
          breakpointsMobile.includes(screenSize) ? true : false
        "
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          class="slider-item"
          v-for="(item, index) in filteredTariffs"
          :key="index"
        >
          <div
            class="tariff"
            :class="{ black: item.slug === 'black' || item.slug === 'premium' }"
          >
            <div class="tariff-item tariff-item_1">
              <div class="tariff-title">{{ item.name }}</div>
              <!-- <div v-if="item.freezePrice" class="tariff-freeze">
                <img
                  v-if="item.freezePrice.icon"
                  :src="item.freezePrice.icon"
                  alt=""
                />
                <span
                  v-if="item.freezePrice.frontName"
                  v-html="item.freezePrice.frontName"
                />
              </div> -->
            </div>
            <div class="tariff-item tariff-item_2">
              <div
                v-if="item.unlimitedTele2_calls"
                v-html="item.unlimitedTele2_calls"
                class="tariff-option string"
              />
              <div v-if="item.minutes" class="tariff-option">
                <b v-if="item.minutes?.value">
                  {{ item.minutes.value }}
                </b>
                <span v-if="item.minutes?.frontName">
                  минут {{ item.minutes.frontName }}
                </span>
              </div>
              <div v-if="item.internetPackage" class="tariff-option">
                <b v-if="item.internetPackage?.value">
                  {{ item.internetPackage.value }}
                </b>
                <sup v-if="item.internetPackage?.uom">
                  {{ item.internetPackage.uom }}
                </sup>
                <!-- <span
                  v-if="item.internetPackage?.bonus"
                  class="tariff-option tariff-option_span"
                >
                  <b> +{{ item.internetPackage.value }}</b>
                  <span
                    >{{ item.internetPackage.uom }} каждый<br />
                    3-й месяц</span
                  >
                </span> -->
              </div>
              <template v-if="item.slug === 'social'">
                <div v-if="item.messagesPackage" class="tariff-option">
                  <b v-if="item.messagesPackage?.value">
                    {{ item.messagesPackage.value }}
                  </b>
                  <sup v-if="item.messagesPackage?.uom">
                    {{ item.messagesPackage.uom }}
                  </sup>
                </div>
              </template>
              <div v-if="item.internetTele2_home" class="tariff-home">
                <ToggleButton @on-click="item.showCost = !item.showCost" />
                <div class="tariff-home-info">
                  <span
                    v-if="item.internetTele2_home.frontName"
                    v-html="item.internetTele2_home.frontName"
                  />
                  <span
                    v-if="item.internetTele2_home.bonus"
                    v-html="item.internetTele2_home.bonus"
                  />
                </div>
              </div>
            </div>
            <div class="tariff-item tariff-item_3">
              <div class="tariff-list">
                <ul>
                  <template
                    v-for="(social, i) of getSocial(item.features)"
                    :key="i"
                  >
                    <li v-if="social.icon && social.frontName">
                      <img :src="social.icon" alt="" />
                      <span v-html="remakeHREF(social.frontName)" />
                    </li>
                  </template>
                  <li v-if="!item.price" class="tariff-custom-price">
                    Единая стоимость звонков на все номера домашнего региона
                  </li>
                  <li v-if="!item.price" class="tariff-custom-price">
                    <b>3,00 ₽</b> за минуту звонка на все номера домашнего
                    региона и Tele2 России
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="item.hit" class="tariff-item tariff-item_4 tariff-flag">
              <div
                :style="{ backgroundColor: item.hit.color }"
                class="tariff-label"
              >
                {{ item.hit.text }}
              </div>
            </div>
            <div class="tariff-item tariff-item_5">
              <div class="tariff-cart">
                <div v-if="item.price" class="tariff-price">
                  <div class="tariff-price-cost">
                    <strong>{{ item.price }}<span>₽</span></strong>
                    <strong
                      v-if="item.internetTele2_home?.bonus"
                      :class="{ hidden: !item.showCost }"
                    >
                      <span>
                        {{ +item.internetTele2_home?.cost + +item.price }}
                      </span>
                    </strong>
                  </div>
                  <div>в месяц</div>
                </div>
                <a
                  v-if="item.url"
                  :href="item.url"
                  target="_blank"
                  class="btn btn-default tariff-button"
                  >Купить</a
                >
              </div>
              <div
                v-if="existModalsSuit.includes(item.slug)"
                class="tariff-more"
              >
                <button
                  @click="openModalSuit(item.slug)"
                  class="btn-link btn-block tariff-button tariff-button-suit"
                >
                  <span>Узнать кому подойдет</span>
                </button>
              </div>
              <div v-else-if="item.slug === 'social'" class="tariff-more">
                <button
                  @click="scroll('#social-tariff')"
                  class="btn-link btn-block tariff-button tariff-button-suit"
                >
                  <span>Условия подключения</span>
                </button>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <ModalSuit
    v-if="!!modalShowSuit"
    :slug="modalShowSuit"
    @close="modalShowSuit = false"
  />
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import ModalSuit from "./ModalSuit.vue";
import ToggleButton from "@/components/ToggleButton.vue";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [
          { slug: "my-conversation" },
          { slug: "my-online" },
          { slug: "my-online-plus" },
          { slug: "gaming" },
          { slug: "black" },
          { slug: "premium" },
          { slug: "classic" },
        ];
      },
    },
    slideIndex: {
      type: Number,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    ModalSuit,
    ToggleButton,
  },
  data() {
    return {
      modalShowSuit: false,
      existModalsSuit: [
        "my-conversation",
        "my-online",
        "my-online-plus",
        "gaming",
        "black",
        "classic",
      ],
      socialTariff: {
        name: "Социальный",
        hit: { color: "#FF59A3", text: "Эксклюзив" },
        slug: "social",
        url: "",
        price: 150,
        freezePrice: {
          frontName:
            '<a href="https://freeze.tele2.ru/" target="_blank">Заморозка цены</a>',
          icon: "https://tele2.ru/api/media/asset?mediaId=m6660005",
        },
        unlimitedTele2_calls:
          "<strong>безлимит</strong> на Tele2 домашнего региона",
        minutes: {
          value: "100",
          frontName: "минут на номера домашнего региона и Tele2 России",
        },
        internetPackage: { uom: "ГБ", value: 3, bonus: false },
        messagesPackage: {
          uom: "SMS на все номера домашнего региона",
          value: "100",
        },
        internetTele2_home: null,
        features: {
          messengers: { frontName: null, icon: null },
          socialNetworks: { frontName: null, icon: null },
          advantages: [
            {
              frontName: "WhatsApp и еще 2 безлимитных мессенджера",
              icon: require("@/assets/icons/social-media/whatsapp2.svg"),
              priority: 10,
            },
            {
              frontName: "ВКонтакте и еще 1 безлимитная соцсеть",
              icon: "https://tele2.ru/api/media/asset?mediaId=m4580003",
              priority: 30,
            },
            {
              frontName:
                "Безлимитный интернет на Яндекс.Карты и Яндекс.Навигатор",
              icon: require("@/assets/icons/social-media/yandex-map.svg"),
              priority: 30,
            },
          ],
          gamingAdvantages: [],
        },
      },
    };
  },
  computed: {
    ...mapState({
      tariffs: (state) => state.api.tariffs,
      status: (state) => state.api.tariffsStatus,
    }),
    filteredTariffs() {
      const tarifsToShow = [
        "social",
        "black",
        "super-online-plus",
        "my-online-plus",
        "my-online",
        "my-conversation",
        "gaming",
        "premium",
      ];
      return [this.socialTariff, ...this.tariffs].filter((t) =>
        tarifsToShow.includes(t.slug)
      );
    },
  },
  watch: {
    slideIndex(index) {
      this.mySwiper.slideToLoop(index, 0, false);
    },
  },
  methods: {
    openModalSuit(slug) {
      this.modalShowSuit = slug;
    },
    fullPath(url) {
      return `https://tele2.ru${url}`;
    },
    remakeHREF(str) {
      if (str.includes("Selection")) {
        return str.replace(
          "/bolshe/selection?pageParams=view%3Dlanding",
          "https://msk.tele2.ru/bolshe/selection?pageParams=view%3Dlanding"
        );
      }
      return str;
    },
    getSocial(features) {
      // remove if link fix
      const brokenString =
        '<a href="/option/4g-turbo">4G Turbo</a> и <a href="https://selection.tele2.ru/">привилегии программы Tele2 Selection</a>';
      const broken = features.advantages.find(
        (a) => a.frontName === brokenString
      );
      if (broken) {
        broken.frontName =
          '<a href="https://tele2.ru/option/4g-turbo" target="_blank">4G Turbo</a> и <a href="https://selection.tele2.ru/" target="_blank">привилегии программы Tele2 Selection</a>';
      }
      // end remove if link fix
      return [
        ...features.advantages,
        ...features.gamingAdvantages,
        features.messengers,
        features.socialNetworks,
      ];
    },
  },
  setup() {
    const realIndex = ref(0);
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };
    const onSlideChange = (swiper) => {
      realIndex.value = swiper.realIndex;
    };

    return {
      mySwiper,
      realIndex,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style lang="scss">
.tariffs {
  width: 100vw;
  background-color: #fff;
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  @include lg {
    padding: 50px var(--widthGutter) 100px var(--widthGutter);
    background-color: #f5f5f5;
    width: calc(100vw - 30px);
    margin: 0 auto;
  }
  > h2 {
    padding: 0 var(--widthGutter);
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      padding: 0;
    }
  }
  &__inner {
    max-width: var(--widthContainer);
    margin: 0 auto;
  }
  .slider {
    width: 100%;
    margin-top: -16px;
    @include lg {
      max-width: calc(var(--widthContainer) + 48px);
      margin: 0 auto;
      padding: 0px 24px;
    }
    .swiper {
      @include lg {
        padding: 24px;
        margin: 0 -24px;
      }
    }
    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-slide {
      width: auto;
    }
    &-item {
      // padding: var(--widthGutter);
      height: auto;
    }
    &-content {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
    }
    &-title {
      font-size: 40px;
    }
  }

  .tariff {
    width: 256px;
    height: calc(100% - 32px);
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
    margin: 16px 0;
    padding: 16px 18px;
    position: relative;
    display: grid;
    // grid-template-rows: 144px 160px;
    @include lg {
      width: 100%;
      height: 256px;
      display: grid;
      padding: 27px 53px 27px 27px;
      grid-template-columns:
        minmax(210px, 237px) minmax(200px, auto) minmax(200px, auto)
        143px;
      // grid-template-columns:
      //   minmax(210px, 237px) minmax(200px, 252px) minmax(200px, 343px)
      //   143px;
      margin: 0;
      // grid-template-columns: repeat(4, auto);
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &_1 {
        margin-bottom: 31px;
        @include lg {
          margin-right: 24px;
          margin-bottom: 0;
        }
      }
      &_2 {
        margin-bottom: 32px;
        @include lg {
          margin-bottom: 0;
          margin-right: 39px;
        }
      }
      &_3 {
        // @include lg {
        //   margin-right: 36px;
        // }
      }
      &_4 {
      }
      &_5 {
        margin-top: 16px;
        justify-content: flex-end;
        @include lg {
          display: grid;
          align-items: baseline;
          margin-top: 0;
        }
      }
    }
    &.black {
      background: linear-gradient(
        164.79deg,
        #282c34 0%,
        #373c48 83%,
        #494e59 87.51%,
        #373c48 91.88%,
        #282c34 100%
      );
      color: #fff;
      .tariff-button {
        border-color: #fff;
      }
      .tariff-list img {
        filter: brightness(0) invert(1);
      }
      .tariff-label::before {
        border-color: transparent #373c48 transparent transparent;
      }
      @include lg {
        .tariff-label::before {
          border-color: transparent #2d313a transparent transparent;
        }
      }
    }
    &-flag {
      margin-top: 18px;
      @include lg {
        position: absolute;
        transform: none;
        top: 187px;
      }
    }
    &-title {
      font-family: var(--fontSerif);
      font-weight: 700;
      font-size: 27px;
      line-height: 36px;
      @include lg {
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 0;
      }
    }
    &-option {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 6px;
      margin-bottom: 3px;
      &.string {
        display: inline-block;
      }
      &.tariff-option_span {
        align-items: center;
        font-size: 14px;
        margin-bottom: 0;
        color: #ff59a3;
        @include lg {
          align-items: flex-end;
        }
      }
      @include lg {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 7px;
      }
      span {
        @include lg {
          font-size: 14px;
          line-height: 18px;
          max-width: 170px;
        }
      }
      b {
        font-family: var(--fontSerif);
        font-size: 28px;
        line-height: 36px;
        @include lg {
          font-weight: 400;
        }
      }
      sup {
        font-size: 14px;
      }
    }
    &-list {
      @include lg {
        margin-top: 0;
      }
      ul {
        @include reset-list;
        display: grid;
        gap: 10px;
        @include lg {
          gap: 16px;
        }
        li {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 6px;
          @include lg {
            gap: 9px;
          }
          img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            object-position: 50%;
          }
          span {
            font-size: 16px;
            line-height: 20px;
            @include lg {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
    &-label {
      width: 123px;
      position: relative;
      font-family: var(--fontSerif);
      display: inline-block;
      background: #ff59a3;
      font-size: 13px;
      line-height: 18px;
      padding-left: 25px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 20px;
      margin-left: -18px;
      color: #fff;
      @include lg {
        margin-left: 0;
      }
      &::before {
        position: absolute;
        top: 0;
        right: -1px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 8px 16px 0;
        border-color: transparent #ffffff transparent transparent;
        content: "";
      }
    }
    &-end {
      height: auto;
      min-height: 80px;
    }
    &-cart {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: flex-end;
      justify-content: space-between;
      @include lg {
        width: 103px;
        margin-left: auto;
        grid-template-columns: 1fr;
        justify-content: flex-start;
        margin-top: 0;
      }
    }
    &-price {
      font-size: 14px;
      line-height: 12px;

      &-cost {
        display: flex;
        // align-items: center;
        flex-direction: column-reverse;

        strong:nth-child(2) {
          span {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 8px;
              bottom: 0;
              left: 0;
              right: -3px;
              display: block;
              border-top: 3px solid #ff59a3;
              transform: rotate(-20deg);
            }
          }
          &.hidden {
            visibility: hidden;
          }
        }

        @include lg {
          align-items: center;
          flex-direction: row;
        }
      }
      @include lg {
        width: 83px;
        // display: flex;
        // flex-direction: column;
        // align-items: flex-end;

        margin-left: auto;
        margin-bottom: 17px;
        font-size: 16px;
        line-height: 22px;
      }
      strong {
        font-family: var(--fontSerif);
        font-weight: 700;
        font-size: 32px;
        line-height: 28px;
        @include lg {
          font-weight: 400;
          font-size: 38px;
          line-height: 44px;
          display: flex;
          align-items: flex-start;
        }
      }
      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        padding-left: 6px;
        @include lg {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          padding-left: 2px;
        }
      }
    }
    &-home {
      display: grid;
      grid-template-columns: 50px auto;
      column-gap: 10px;
      align-items: center;
      &-info {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 20px;
        @include lg {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    &-custom-price {
      display: block !important;
      font-size: 16px;
      b {
        font-size: 18px;
      }
      @include lg {
        font-size: 14px;
        b {
          font-size: 16px;
        }
      }
    }
    &-more {
      margin-top: 30px;
      text-align: center;
      font-weight: 700;
    }
    &-button {
      &-suit {
        @include reset-button;
      }
      @include lg {
        font-size: 14px;
        line-height: 17px;
        padding: 0;
      }
    }
    &-freeze {
      display: flex;
      align-items: flex-end;
      column-gap: 5px;
      margin-top: 5px;
      color: #3fcbff;

      img {
        height: 22px;
        width: 22px;
      }
    }
  }
  .swiper-pagination {
    margin-top: 8px;
  }
}
</style>

<style lang="scss">
.tariff-list span a {
  text-decoration: underline;
}
</style>
