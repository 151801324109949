<template>
  <div id="advantages" class="advantages">
    <h2>Преимущества Tele2</h2>
    <div class="slider">
      <swiper
        :modules="modules"
        :slides-per-view="breakpointsMobile.includes(screenSize) ? 'auto' : 1"
        :slidesOffsetBefore="breakpointsMobile.includes(screenSize) ? 16 : 0"
        :space-between="breakpointsMobile.includes(screenSize) ? 16 : 0"
        :navigation="{
          nextEl: '.button-next',
          prevEl: '.button-prev',
        }"
        :pagination="true"
        :loop="true"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="swiper-adventages"
      >
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">01</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/1.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">Обмен минут на&nbsp;кино</div>
                <div class="slider-desc">
                  Меняйте минуты на фильмы из каталога онлайн-кинотеатра Wink.
                </div>
              </div>
              <div class="slider-attach c">
                <div class="slider-option">
                  <div class="slider-option__icon">
                    <img src="@/assets/icons/wink.svg" alt="" />
                  </div>
                  <div class="slider-option__text">
                    Более 4000 фильмов и сериалов
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">02</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/2.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">Не блокируем при&nbsp;минусе</div>
                <div class="slider-desc">
                  Пользуйтесь оплаченными пакетами минут и ГБ даже при
                  отрицательном балансе.
                </div>
              </div>
              <div class="slider-attach c"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">03</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/3.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">Вечные минуты и&nbsp;ГБ</div>
                <div class="slider-desc">
                  Неиспользованные вами за месяц минуты, гигабайты и SMS,
                  включенные&nbsp;в&nbsp;тарифный план, не&nbsp;сгорят, а
                  бесплатно перенесутся на&nbsp;следующий месяц.
                </div>
              </div>
              <div class="slider-attach c"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">04</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/4.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">Делитесь ГБ</div>
                <div class="slider-desc">
                  Делитесь интернет-трафиком с&nbsp;друзьями, близкими или
                  с&nbsp;любыми&nbsp;другими абонентами Tele2&nbsp;пакетных
                  тарифов по всей России.
                </div>
              </div>
              <div class="slider-attach c">
                <div class="slider-option">
                  <div class="slider-option__icon">
                    <img src="@/assets/icons/gift.svg" alt="" />
                  </div>
                  <div class="slider-option__text">
                    Радуйте тех, кто вам дорог!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">05</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/5.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">По России без&nbsp;доплат</div>
                <div class="slider-desc">
                  В поездках по России принимайте бесплатно входящие вызовы
                  и&nbsp;пользуйтесь минутами
                  и&nbsp;гигабайтами,&nbsp;включенными в&nbsp;тарифный план.
                </div>
              </div>
              <div class="slider-attach c"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">06</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/6.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">Честные звонки внутри сети</div>
                <div class="slider-desc">
                  Общайтесь безлимитно с&nbsp;абонентами&nbsp;Tele2, не расходуя
                  пакет&nbsp;минут, включенный в&nbsp;тарифный&nbsp;план.
                </div>
              </div>
              <div class="slider-attach c"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">07</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/7.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">SOS-пакет</div>
                <div class="slider-desc">
                  Оставайтесь на связи, даже если закончились деньги на счете и
                  нет возможности внести плату на&nbsp;тарифный план.
                </div>
              </div>
              <div class="slider-attach c">
                <div class="slider-option slider-option_type_row">
                  <div class="slider-option__text">
                    Пользуйтесь бесплатно самыми необходимыми&nbsp;приложениями:
                  </div>
                  <div class="slider-option__icons">
                    <ul>
                      <li><img src="@/assets/icons/whatsapp.svg" alt="" /></li>
                      <li><img src="@/assets/icons/viber.svg" alt="" /></li>
                      <li><img src="@/assets/icons/telegram.svg" alt="" /></li>
                      <li>
                        <img src="@/assets/icons/yandex_maps.svg" alt="" />
                      </li>
                      <li>
                        <img src="@/assets/icons/yandex_nav.svg" alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slider-item">
          <div class="slider-content">
            <div class="swiper-navigation">
              <button type="button" class="button-prev">
                <img src="@/assets/icons/btn-prev.svg" />
              </button>
              <button type="button" class="button-next">
                <img src="@/assets/icons/btn-next.svg" />
              </button>
            </div>
            <div class="slider-body">
              <div class="slider-number">08</div>
              <div class="slider-pic a">
                <img src="/static/demo/advantages/8.png" alt="" />
              </div>
              <div class="slider-text b">
                <div class="slider-title">
                  Защита от&nbsp;нежелательных подписок
                </div>
                <div class="slider-desc">
                  Не беспокойтесь о случайном подключении подписок в интернете.
                  При подключении подписки вы получите код подтверждения: пока
                  его не введете, подписка не подключится.
                </div>
              </div>
              <div class="slider-attach c"></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [
          { title: "Обмен минут на кино" },
          { title: "Обмен минут на Xiaomi" },
          { title: "Не блокируем при минусе" },
          { title: "Вечные минуты и ГБ" },
          { title: "Делитесь ГБ" },
          { title: "По России без доплат" },
          { title: "Честные звонки внутри сети" },
          { title: "SOS-пакет" },
          { title: "Защита от нежелательных подписок" },
        ];
      },
    },
    slideIndex: {
      type: Number,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    slideIndex(index) {
      this.mySwiper.slideToLoop(index, 0, false);
    },
  },
  setup() {
    const realIndex = ref(0);
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };
    const onSlideChange = (swiper) => {
      realIndex.value = swiper.realIndex;
    };

    return {
      mySwiper,
      realIndex,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style lang="scss">
.advantages {
  width: 100vw;
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  @include lg {
    padding: 50px 0;
    width: calc(100vw - 30px);
    margin: 0 auto;
  }
  > h2 {
    padding: 0 var(--widthGutter);
    @include lg {
      padding: 0;
      max-width: var(--widthContainer);
      margin: 0 auto;
    }
  }
  .slider {
    width: 100%;
    .swiper-adventages {
      @include lg {
        padding: 24px 0 0 0;
      }
    }
    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-navigation {
      position: absolute;
      bottom: 40px;
      right: 40px;
      z-index: 1;
      display: none;
      @include lg {
        display: block;
      }
      button {
        @include reset-button;
        &:last-child {
          margin-left: 1px;
        }
      }
    }
    &-item {
      height: 444px;
      width: 285px;
      @include lg {
        height: auto;
      }
    }
    &-content {
      background: #ffffff;
      border-radius: 4px;
      height: 100%;
      position: relative;
      padding: 10px 20px 24px;
      display: grid;
      grid-template-rows: 1fr auto;
      box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
      @include lg {
        max-width: var(--widthContainer);
        margin: 0 auto;
        padding: 48px 95px 48px;
        width: calc(100vw - 69px);
      }
    }
    &-body {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
        "a"
        "b"
        "c";
      @include lg {
        grid-template-columns: 348px 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          "a b"
          "a c";
        grid-column-gap: 132px;
        grid-row-gap: 32px;
      }
    }
    &-number {
      position: absolute;
      top: 20px;
      left: 0;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #1f2229;
      font-family: var(--fontSerif);
      &::before {
        content: "";
        width: 16px;
        height: 8px;
        background: #1f2229;
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
    }
    &-text {
      @include lg {
        margin-top: 24px;
        max-width: 392px;
      }
    }
    &-pic {
      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
        object-position: 50%;
        margin: auto;
        display: block;
        @include lg {
          width: 348px;
          height: 348px;
        }
      }
    }
    &-title {
      font-family: var(--fontSerif);
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      @include lg {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 16px;
      }
    }
    &-desc {
      margin-top: 16px;
      @include lg {
        font-size: 22px;
        line-height: 28px;
      }
    }
    &-option {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 8px;
      align-items: center;
      &_type_row {
        grid-auto-flow: row;
        grid-template-columns: auto;
      }
      &__icons {
        ul {
          @include reset-list;
          display: flex;
          li {
            + li {
              margin-left: 8px;
            }
            img {
              width: 28px;
              height: 28px;
              object-fit: contain;
              object-position: 50%;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
