<template>
  <div id="promo" class="promo">
    <div>
      <h1>
        Цифровая <br class="desktop" />
        <span>папка продавца.</span><br />
        Почта России
      </h1>
      <p>Вся актуальная информация, собранная в одном месте</p>
    </div>
    <div>
      <img src="@/assets/promo.png" width="244" height="261" alt="" />
      <button @click="scroll('#navigation')"></button>
    </div>
  </div>
</template>

<script></script>
<style lang="scss">
body.region-confirm {
  .promo {
    --height: 91px;
    min-height: calc(100vh - var(--height));
    max-height: 600px;
    @include lg {
      min-height: 400px;
    }
  }
}
</style>
<style lang="scss" scoped>
.promo {
  --height: 45px;
  background-color: var(--colorDarkSecond);
  color: var(--colorLight);
  min-height: 400px;
  max-height: 600px;
  // min-height: calc(var(--vh) * 100 - var(--height));
  display: grid;
  position: relative;
  background-image: url("@/assets/glow-m.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100% auto;
  grid-template-rows: 1fr auto;
  @include lg {
    min-height: 400px;
    background-image: url("@/assets/promo-bg-desktop.png");
    background-position: center right;
    background-size: auto 100%;
    padding: 0 var(--widthGutter);
    align-items: center;
  }
  > div {
    width: 100%;
    padding: 24px var(--widthGutter);
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      padding: 0;
    }
    &:last-child {
      padding: 24px var(--widthGutter) 140px var(--widthGutter);
      @include lg {
        padding: 0;
      }
    }
  }
  img {
    margin: 0 auto;
    display: block;
    @include lg {
      display: none;
    }
  }
  button {
    @include reset-button;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.3333 12 16 22.6667 26.6667 12' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    width: 32px;
    height: 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin: 0 auto;
    @include lg {
      display: none;
    }
  }
  h1 {
    @include lg {
      font-weight: 700;
      font-size: 72px;
      line-height: 88px;
      max-width: 649px;
      margin-bottom: 24px;
    }

    span {
      white-space: nowrap;
    }
  }
  p {
    @include lg {
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
    }
  }
  br.desktop {
    display: none;
    @include lg {
      display: block;
    }
  }
}
</style>
