<template>
  <div id="personal" class="personal">
    <div class="personal__head">
      <h2>Обновление персональных данных</h2>
    </div>
    <div class="personal__body">
      <div class="personal__item item-personal item-personal_split">
        <h3 class="item-personal__title">Вариант 1</h3>
        <p class="item-personal__sub-title">
          Установите приложение «Мой Tele2»
        </p>
        <p class="item-personal__text">
          Включите камеру своего смартфона и отсканируйте QR-код. Он приведет
          вас в нужный раздел приложения. Выберите удобный для вас способ
          актуализации данных и пройдите несколько простых шагов.
        </p>
        <QRLink
          class="item-personal__qr"
          link="https://q.tele2.ru/lk"
          src="/personal/Layer_1.png"
        />
      </div>

      <hr class="personal__line" />

      <div class="personal__item item-personal">
        <h3 class="item-personal__title">Вариант 2</h3>
        <p class="item-personal__sub-title">Войдите в приложение «Мой Tele2»</p>
        <div class="item-personal__slider">
          <Swiper v-bind="swiperOptions">
            <SwiperSlide
              v-for="(slide, i) of slides"
              :key="i"
              class="item-personal__slide"
            >
              <h4 class="item-personal__slide-title">
                {{ i + 1 }}
              </h4>
              <p class="item-personal__slide-text">
                {{ slide.title }}
              </p>
              <img
                :src="slide.image"
                width="217"
                height="470"
                :alt="slide.title"
                class="item-personal__slide-image"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import QRLink from "@/components/QRLink.vue";

/**
 * @type {import("swiper").SwiperOptions}
 */
const swiperOptions = {
  modules: [Pagination],
  pagination: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
    // 1024: {
    // },
    1440: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
  },
};

const slides = [
  {
    title: "Войдите в свой профиль на главном экране приложения.",
    image: "/personal/1.jpg",
  },
  {
    title:
      "Нажмите на информационное сообщение или выберите раздел «Паспортные данные».",
    image: "/personal/2.jpg",
  },
  {
    title: "Выберите удобный для вас способ актуализации данных.",
    image: "/personal/3.jpg",
  },
];
</script>

<style lang="scss" scoped>
.personal {
  overflow: hidden;
  padding: 40px var(--widthGutter);
  background: #f3f5f6;
  // .personal__head
  &__head {
    h2 {
      margin: 0 0 32px;
      font-size: 28px;
      color: #1f2229;
    }
  }
  // .personal__body
  &__body {
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 8px;
  }
  // .personal__item
  &__item {
  }
  // .personal__line
  &__line {
    width: 100%;
    height: 2px;
    background-color: #dfe4e3;
    border: none;
    margin: 0px 0px 32px 0px;
  }
}
.item-personal {
  // .item-personal__title
  &__title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--fontSerif);
    text-transform: capitalize;
  }
  // .item-personal__sub-title
  &__sub-title {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  // .item-personal__text
  &__text {
    margin: 0 0 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  // .item-personal__qr
  &__qr {
    max-width: unset;
    margin: 0 0 32px;
    :deep(.qr-link__img) {
      display: block;
      margin: 0 auto;
      border: none;
      padding: 0;

      img {
        width: 313px;
        height: 313px;
      }

      &::before {
        right: 35px;
        bottom: -28px;
      }
    }
  }
  // .item-personal__slider
  &__slider {
    display: grid;
    overflow: hidden;
    :deep(.swiper) {
      max-width: 100%;
    }
    :deep(.swiper-pagination) {
      margin-left: -35px; // 20 + 15
    }
  }
  // .item-personal__slide-title
  &__slide-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 0 16px;
    border-radius: 50%;
    border: 1.4px solid #3fcbff;
    color: #3fcbff;
    font-size: 16px;
    font-family: var(--fontSerif);
    line-height: normal;
  }
  // .item-personal__slide-text
  &__slide-text {
    @extend .item-personal__text;
    height: 66px;
    margin-bottom: 16px;
  }
  // .item-personal__slide-image
  &__slide-image {
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .personal {
    max-width: 1116px;
    padding: 24px var(--widthGutter) 90px;
    margin: 0 auto;
    // .personal__head
    &__head {
      h2 {
        font-size: 38px;
      }
    }
    // .personal__body
    &__body {
      padding: 58px;
      border-radius: 16px;
    }
    // .personal__item
    &__item {
    }
    // .personal__line
    &__line {
      margin-bottom: 40px;
    }
  }
  .item-personal {
    &_split {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(5, auto);
      margin-bottom: 40px;
      column-gap: 15px;

      .item-personal__qr {
        grid-row: 1 / -1;
        grid-column: 2;
      }
    }
    // .item-personal__title
    &__title {
      margin-bottom: 16px;
      font-size: 32px;
      line-height: 44px;
    }
    // .item-personal__sub-title
    &__sub-title {
      margin: 0 0 24px;
      font-size: 22px;
      line-height: 26px;
      max-width: 552px;
    }
    // .item-personal__text
    &__text {
      margin: 0;
      font-size: 22px;
      line-height: 26px;
    }
    // .item-personal__qr
    &__qr {
      margin: 0;
      :deep(.qr-link__img) {
        img {
          width: 271px;
          height: 271px;
        }

        &::before {
          transform: scale(0.8);
          right: 24px;
          bottom: -28px;
        }
      }
      :deep(.qr-link__caption) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    // .item-personal__slider
    &__slider {
      display: grid;
      overflow: hidden;
      :deep(.swiper) {
        max-width: 100%;
      }
      :deep(.swiper-pagination) {
        margin-left: -73px; // 58 + 15
      }
    }
    // .item-personal__slide-title
    &__slide-title {
    }
    // .item-personal__slide-text
    &__slide-text {
    }
    // .item-personal__slide-image
    &__slide-image {
      width: 310px;
      height: 671px;
    }
  }
}

@media (min-width: 1440px) {
  .item-personal {
    // .item-personal__slider
    &__slider {
      :deep(.swiper-pagination) {
        display: none;
      }
    }
    // .item-personal__slide-title
    &__slide-title {
      width: 40px;
      height: 40px;
      font-size: 19.5px;
    }
    // .item-personal__slide-text
    &__slide-text {
      height: 78px;
      margin-bottom: 24px;
    }
  }
}
</style>
