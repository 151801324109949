<template>
  <div id="technology" class="technoglgy">
    <div class="technoglgy-head">
      <h2>{{ technology.title }}</h2>
      <p class="desc">
        {{ technology.subtitle1 }}
      </p>
      <img
        class="technoglgy-head-pic"
        :src="mediaUrl(technology.image)"
        width="125"
        height="129"
        alt=""
        v-if="technology.image"
      />
      <p class="technoglgy-head-text">
        {{ technology.subtitle2 }}
      </p>
    </div>
    <div class="technoglgy-list">
      <div class="collapse" :class="{ collapsed: collapsed1 }">
        <div class="collapse-head" @click="collapsed1 = !collapsed1">
          <div class="collapse-head__title">Преимущества</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params">
            <div
              class="collapse-params__item"
              v-for="(benefit, index) in technology.benefits"
              :key="index"
            >
              <div class="collapse-params__icon">
                <img src="@/assets/icons/Union2.svg" alt="" />
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ benefit.title }}
                </div>
                <div class="collapse-params__desc">
                  {{ benefit.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed2 }">
        <div class="collapse-head" @click="collapsed2 = !collapsed2">
          <div class="collapse-head__title">Доступно для устройств</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="devices_head">
            Список популярных устройств, на которых работает технология.
          </div>
          <div class="collapse-params collapse-params__grid4">
            <div
              class="collapse-params__item"
              v-for="(device, index) in technology.devices"
              :key="index"
            >
              <div class="collapse-params__content">
                <div class="collapse-params__pic">
                  <img :src="mediaUrl(device.logo)" alt="" />
                </div>
                <div
                  class="collapse-params__desc collapse-params__desc_only_list"
                >
                  <ul>
                    <li v-for="(item, index) in device.options" :key="index">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed3 }">
        <div class="collapse-head" @click="collapsed3 = !collapsed3">
          <div class="collapse-head__title">Подключение новому клиенту</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-steps">
            <div
              class="collapse-steps__item"
              v-for="(step, index) in technology.new_sub"
              :key="index"
            >
              <div class="collapse-steps__body">
                <div class="collapse-steps__number">0{{ step.step }}</div>
                <div class="collapse-steps__content">
                  <div class="collapse-steps__title">
                    {{ step.title }}
                  </div>
                  <div class="collapse-steps__desc" v-html="step.text" />
                </div>
              </div>
              <div
                class="collapse-steps__caption"
                v-if="step.explanation"
                v-html="step.explanation"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed4 }">
        <div class="collapse-head" @click="collapsed4 = !collapsed4">
          <div class="collapse-head__title">Активация eSIM</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params collapse-params--grid1">
            <div class="collapse-params__item" v-if="technology.activation">
              <div class="collapse-params__content">
                <div class="collapse-params__desc">
                  <p>
                    {{ technology.activation.title }}
                  </p>
                  <img :src="mediaUrl(technology.activation.image)" alt="" />
                  <img :src="mediaUrl(technology.activation.image2)" alt="" />
                  <div v-html="technology.activation.steps"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed5 }">
        <div class="collapse-head" @click="collapsed5 = !collapsed5">
          <div class="collapse-head__title">Решение проблем</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params collapse-params__grid3">
            <div
              class="collapse-params__item"
              v-for="(troubleshoot, index) in technology.troubleshoots"
              :key="index"
            >
              <div class="collapse-params__icon">
                <picture>
                  <source
                    srcset="@/assets/icons/danger.svg"
                    media="(max-width: 1024px)"
                  />
                  <img src="@/assets/icons/Union2.svg" alt="img" />
                </picture>
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ troubleshoot.title }}
                </div>
                <div class="collapse-params__desc">
                  <p>
                    {{ troubleshoot.text }}
                  </p>
                  <p>
                    <a
                      :href="troubleshoot.link"
                      target="_blank"
                      rel="noopener noreferrer"
                      v-if="troubleshoot.link"
                      >{{ troubleshoot.link_text }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mediaUrl } from "@/utils";

export default {
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  data() {
    return {
      collapsed1: false,
      collapsed2: false,
      collapsed3: false,
      collapsed4: false,
      collapsed5: false,
    };
  },
  computed: {
    ...mapState({
      technology: (state) => state.esim.esim,
    }),
  },
};
</script>

<style lang="scss">
.technoglgy {
  ol {
    margin-left: 24px;
    gap: 8px;

    li {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
</style>

<style lang="scss" scoped>
.technoglgy {
  background: #6bc8fa;
  padding: 0 var(--widthGutter);
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  @include lg {
    padding-top: 72px;
  }

  &-head {
    display: grid;
    grid-template-columns: [first] 1fr [center] auto [last];
    grid-template-rows: [first] auto auto auto [last];
    align-items: center;
    gap: 5px;
    margin-bottom: 18px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto 40px auto;
      gap: 16px;
      align-items: flex-start;
    }

    > h2 {
      grid-column: first / last;
      margin-bottom: 0;
      @include lg {
        font-weight: 700;
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 8px;
      }
    }

    .technoglgy-head-text {
      grid-column: first / last;
      font-size: 14px;
      @include lg {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }

    p {
      margin: 0;
      @include lg {
        font-size: 22px;
        line-height: 26px;
      }
    }

    img {
      @include lg {
        grid-column: last;
        grid-row: first / last;
        width: 280px;
        height: 290px;
        margin-top: -58px;
      }
    }
  }

  &-list {
    display: grid;
    gap: 8px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
      border-radius: 16px;
      padding: 0 60px 0 56px;
    }

    .collapse-steps__number {
      position: relative;
    }

    .collapse-steps__number::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 8px;
      left: -21px;
      top: 5px;
      background: #6bc8fa;
      @include lg {
        display: none;
      }
    }
  }

  .devices_head {
    background: #edf0f2;
    padding: 15px;
    border-radius: 8px;
    width: fit-content;
    font-size: 14px;
    margin-bottom: 24px;

    @include lg {
      margin-bottom: 40px;
    }
  }
}

@include lg {
  .collapse {
    &-body {
      padding: 0 0 40px 0;
    }

    &-params {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      &--grid1 {
        grid-template-columns: 1fr;

        .collapse-params__item {
          display: block;
        }

        .collapse-params__desc {
          display: grid;
          grid-template-columns: [first] 240px 241px [c] 1fr [last];
          align-items: center;

          p {
            grid-column: first/last;
            max-width: 768px;
            margin-bottom: 40px;
          }

          img {
            margin: 0;
          }
        }
      }

      &__grid3 {
        grid-template-columns: 1fr 1fr 1fr;

        .collapse-params__desc {
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &__grid4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .collapse-params__item:first-of-type {
          border-top: 1px solid #d1d8d6 !important;

          @include lg {
            border-top: none !important;
          }
        }
      }

      &__item {
        padding-bottom: 0;

        ul {
          margin-left: 0;
        }
      }

      &__desc p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-steps {
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;

      &__body {
        max-width: 225px;
        border-top: none;
      }

      &__item {
        min-width: 200px;
        display: flex;
        align-items: flex-start;
      }

      &__caption {
        max-width: 234px;
        margin-left: 20px;
        margin-top: 15px;
        margin-bottom: 0;
        min-width: 45%;
      }

      &__number {
        font-weight: 700;
        font-size: 35px;
        line-height: 140%;
        margin-top: -8px;
      }

      &__desc {
        font-size: 14px;
      }
    }
  }
}
</style>
