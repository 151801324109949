import Cookies from "js-cookie";
const getUserCityId = Cookies.get("tele2_digitalfolder_user_city_id");

export default {
  namespaced: true,
  state: {
    region: getUserCityId || null,
  },
  mutations: {
    setRegion(state, payload) {
      Cookies.set("tele2_digitalfolder_user_city_id", payload);
      state.region = payload;
    },
  },
};
