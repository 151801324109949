<template>
  <div class="modal" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <transition name="fade">
        <div v-if="show" class="modal-content">
          <div class="modal__head">
            <button class="modal__close" @click="close"></button>
          </div>
          <div class="modal__body">
            <div class="modal__text">
              {{ text }}
            </div>
            <button class="modal__button" @click="onNext">Смотреть</button>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition name="fade">
    <div
      v-if="show && breakpointsMobile.includes(screenSize)"
      class="modal-backdrop"
    ></div>
  </transition>
</template>

<script>
export default {
  name: "ModalMessage",
  emits: ["close"],
  props: {
    text: {
      type: String,
    },
    to: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    if (this.breakpointsMobile.includes(this.screenSize))
      document.body.classList.add("modal-open");
    document.addEventListener("keyup", this.keyPressEscape);
  },
  mounted() {
    this.show = true;
  },
  unmounted() {
    if (this.breakpointsMobile.includes(this.screenSize))
      document.body.classList.remove("modal-open");
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  methods: {
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.close();
      }
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
    onNext() {
      this.close();
      // this.$router.push(this.to);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  @include lg {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: calc(100% + 1px);
    right: 0;
    left: auto;
  }
  &-dialog {
    position: relative;
    width: auto;
    display: flex;
    align-items: flex-end;
    min-height: 100%;
    margin: 0 auto;
    padding: 18px;
    @include lg {
      padding: 0;
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 8px;
    background-color: #dbf5ff;
    width: 100%;
    @include lg {
      width: 339px;
      height: auto;
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.4;
  }
  &__head {
    display: flex;
    justify-content: flex-end;
  }
  &__close {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.1393.1397a.4727.4727 0 0 1 .6684 0L6 5.3319 11.1923.1398a.4727.4727 0 0 1 .6685.6684L6.6685 6.0004l5.1923 5.1923a.4728.4728 0 0 1-.6685.6684L6 6.6688.8077 11.8611a.4726.4726 0 1 1-.6684-.6684l5.1923-5.1923L.1393.808a.4727.4727 0 0 1 0-.6684Z' fill='%230076A1'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 36px;
    height: 36px;
    margin: -8px -8px -4px;
    &::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
      cursor: default;
    }
  }
  &__text {
    font-size: 14px;
    color: #0076a1;
  }
  &__button {
    @include reset-button;
    margin-top: 8px;
    color: #0076a1;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.2s;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
