export default {
  namespaced: true,
  state: {
    sellers: {},
  },
  mutations: {
    setSellers(state, value) {
      state.sellers = value;
    },
  },
};
