<template>
  <div id="navigation" class="navigation">
    <h2>Разделы</h2>
    <ul>
      <li v-for="(item, index) of nav" :key="index">
        <button @click="scroll(item.section)">
          <img :src="require(`@/assets/${item.icon}`)" />
          <span v-html="item.name"></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      nav: (state) => state.nav.list,
    }),
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  padding: 0 var(--widthGutter);
  padding-top: var(--sectionOffsetTop);
  padding-bottom: 41px;
  @include lg {
    padding-top: 62px;
    max-width: calc(var(--widthContainer) + var(--widthGutter) * 2);
    margin: 0 auto;
  }
  ul {
    @include reset-list;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    @include lg {
      grid-template-columns: repeat(4, 1fr);
    }
    li {
      button {
        @include reset-button;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
        border-radius: 8px;
        padding: 12px;
        display: grid;
        width: 100%;
        height: 100%;
        justify-content: start;
        align-content: start;
        text-align: left;
        grid-template-rows: auto auto;
        gap: 10px;
        @include lg {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          min-height: 115px;
        }
        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          object-position: 50% 50%;
          @include lg {
            width: 48px;
            height: 48px;
          }
        }
        span {
          font-family: var(--fontSerif);
          font-weight: 700;
          font-size: 16px;
          display: block;
        }
      }
    }
  }
}
</style>
