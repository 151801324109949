<template>
  <div id="bonuses" class="bonuses">
    <div class="bonuses-head">
      <h2>
        {{ sellers.title }}
      </h2>
      <h3>
        {{ sellers.subtitle }}
        <img class="pic" :src="setMediaUrl(sellers.image)" alt="" />
      </h3>
    </div>
    <div class="bonuses-list">
      <div class="collapse" :class="{ collapsed: collapsed1 }">
        <div class="collapse-head" @click="collapsed1 = !collapsed1">
          <div class="collapse-head__title">Что за скидка</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params col">
            <div
              class="collapse-params__item"
              v-for="(item, index) in sellers.discounts"
              :key="index"
            >
              <div class="collapse-params__icon">
                <img :src="setMediaUrl(item.image)" alt="" />
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed2 }">
        <div class="collapse-head" @click="collapsed2 = !collapsed2">
          <div class="collapse-head__title">Для кого</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params col">
            <div
              class="collapse-params__item"
              v-for="(item, index) in sellers.fow_whom"
              :key="index"
            >
              <div class="collapse-params__icon">
                <img :src="setMediaUrl(item.image)" alt="" />
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed3 }">
        <div class="collapse-head" @click="collapsed3 = !collapsed3">
          <div class="collapse-head__title">Как стать участником</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params col">
            <div
              class="collapse-params__item col"
              v-for="(item, index) in sellers.how_to_involved"
              :key="index"
            >
              <div class="collapse-params__icon">
                <img src="@/assets/icons/Ellipse_130.svg" alt="" />
                <span>{{ item.priority }}</span>
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{ collapsed: collapsed4 }">
        <div class="collapse-head" @click="collapsed4 = !collapsed4">
          <div class="collapse-head__title">Особенности</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div class="collapse-params col">
            <div
              class="collapse-params__item col"
              v-for="(item, index) in sellers.features"
              :key="index"
            >
              <div class="collapse-params__icon">
                <img src="@/assets/icons/Ellipse_131.svg" alt="" />
              </div>
              <div class="collapse-params__content">
                <div class="collapse-params__title">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bluebg"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mediaUrl } from "@/utils";

export default {
  data() {
    return {
      collapsed1: false,
      collapsed2: false,
      collapsed3: false,
      collapsed4: false,
      collapsed5: false,
    };
  },
  computed: {
    ...mapState({
      sellers: (state) => state.sellers.sellers,
    }),
  },
  methods: {
    setMediaUrl(url) {
      return mediaUrl(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.bluebg {
  background: #3fcbff;
  mix-blend-mode: multiply;
  position: absolute;
  height: 200%;
  width: 100%;
  top: 170px;
  left: 0;
  transform: skewY(15deg);
  z-index: -5 !important;
  @include lg {
    top: 240px;
  }
  will-change: transform;
}

.bonuses {
  background: #fff;
  padding: 0 var(--widthGutter);
  padding-top: var(--sectionOffsetTop);
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  @include lg {
    padding-bottom: 80px;
  }

  &-head {
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    align-items: center;
    gap: 5px;
    margin-bottom: 18px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 220px auto 64px auto;
      gap: 50px;
      align-items: flex-start;
    }

    > h2 {
      margin-bottom: 0;
      margin-top: 0;
      max-width: 200px;
      @include lg {
        max-width: 100%;
        font-weight: bold;
        font-size: 38px;
        line-height: 36px;
      }
    }

    > h3 {
      max-width: 200px;
      @media (min-width: 600px) {
        max-width: 100%;
      }
      @include lg {
        font-size: 72px;
        line-height: 40px;
      }

      .pic {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 140px;
        z-index: -5;
        @include lg {
          right: -15px;
          bottom: -175px;
          height: auto;
          width: auto;
        }
      }
    }

    .bonuses-head-text {
      grid-column: first / last;
      font-size: 14px;
      @include lg {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }

    p {
      margin: 0;
      @include lg {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  &-list {
    display: grid;
    gap: 8px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
      border-radius: 16px;
      padding: 20px 65px 60px 58px;
    }

    .collapse-params__item {
      border-top: none !important;
      padding: 0 0 8px 0;
      gap: 12px;
      @include lg {
        padding: 0;
        margin: -16px 0 -8px 0;
        gap: 16px;
        grid-template-columns: none;
      }
    }

    .collapse-params.col {
      @include lg {
        grid-auto-rows: fit-content(100%);
        grid-template-columns: none;
        grid-gap: 32px;
      }
    }

    .collapse-params__item.col {
      @include lg {
        align-content: center;
        grid-template-columns: min-content auto;
        grid-template-rows: none;
        background: #fff;
      }
    }

    .collapse-params__content {
      @include lg {
        justify-content: center;
      }
    }

    .collapse:last-of-type {
      .collapse-body {
        padding-bottom: 0;
      }
      img {
        width: 11px;
        height: 11px;
        @include lg {
          margin-top: 50%;
          width: 11px;
          height: 11px;
        }
      }
    }

    .collapse-params__title {
      font-weight: 400;
    }

    .collapse-params__icon {
      position: relative;

      img {
        width: 25px;
        height: 25px;
        @include lg {
          width: 38px;
          height: 38px;
        }
      }

      span {
        font-family: var(--fontSerif);
        font-weight: 400;
        font-size: 18px;
        color: #3fcbff;
        position: absolute;
        transform: translatex(-50%);
        left: 50%;
        top: 4px;
        @include lg {
          font-size: 25px;
        }
      }
    }
  }
}

@include lg {
  .collapse {
    &-body {
      padding: 0 0 40px 0;
    }

    &-params {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      &--grid1 {
        grid-template-columns: 1fr;

        .collapse-params__item {
          display: block;
        }

        .collapse-params__desc {
          display: grid;
          grid-template-columns: [first] 240px 241px [c] 1fr [last];
          align-items: center;

          p {
            grid-column: first/last;
            max-width: 768px;
            margin-bottom: 40px;
          }

          img {
            margin: 0;
          }

          ol {
            margin-left: 24px;
            gap: 8px;

            li {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }

      &__grid3 {
        grid-template-columns: 1fr 1fr 1fr;

        .collapse-params__desc {
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &__grid4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &__item {
        padding-bottom: 0;

        ul {
          margin-left: 0;
        }
      }

      &__desc p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-steps {
      display: grid;
      grid-template-columns: 2.3fr 1fr 1fr;
      grid-gap: 20px;

      &__body {
        border-top: none;
      }

      &__item {
        display: flex;
        align-items: flex-start;
      }

      &__caption {
        max-width: 234px;
        margin-left: 20px;
        margin-top: 15px;
        margin-bottom: 0;
        min-width: 45%;
      }

      &__number {
        font-weight: 700;
        font-size: 35px;
        line-height: 140%;
        margin-top: -8px;
      }
    }
  }
}
</style>
