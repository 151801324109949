<template>
  <div class="step-item">
    <div class="step-item__pic">
      <swiper
        v-if="img.length > 1"
        direction="vertical"
        :space-between="16"
        :modules="modules"
        :navigation="{
          nextEl: '.step-item-button-next',
          prevEl: '.step-item-button-prev',
        }"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="item in img" :key="item">
          <img :src="item" alt="" />
        </swiper-slide>
        <div class="step-item-navigation">
          <button type="button" class="step-item-button-prev"></button>
          <button type="button" class="step-item-button-next"></button>
        </div>
      </swiper>
      <img v-else-if="img.length === 1" :src="img[0]" alt="" />
    </div>
    <div class="step-item__text">
      <strong class="step-item__number" v-if="number">{{ number }} </strong>
      <span v-html="text"></span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  props: {
    img: {
      type: Array,
      default: () => [],
    },
    number: String,
    text: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };

    return {
      mySwiper,
      onSwiper,
      modules: [Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
.step-item {
  display: grid;
  align-content: start;
  gap: 18px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  width: 242px;
  height: 100%;
  @include lg {
    width: 380px;
    padding: 24px;
    gap: 28px;
  }
  &-navigation {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    position: absolute;
    bottom: 0;
    left: calc(50% + 50px);
    z-index: 1;
    @include lg {
      left: calc(50% + 70px);
      gap: 12px;
    }
  }
  &-button {
    &-prev,
    &-next {
      @include reset-button;
      background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5397 8.4606c-.4657.4657-.4657 1.2207 0 1.6864l9.4915 9.4915-9.4915 9.4916c-.4657.4656-.4657 1.2207 0 1.6864.4657.4656 1.2207.4656 1.6864 0l10.3347-10.3348c.4657-.4657.4657-1.2207 0-1.6864L15.2261 8.4606c-.4657-.4657-1.2207-.4657-1.6864 0Z' fill='%23757575'/%3E%3Crect x='.954' y='.954' width='36.251' height='36.251' rx='5.4059' transform='matrix(0 1 1 0 .0732 .5586)' stroke='%23757575' stroke-width='1.908'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      @include lg {
        width: 39px;
        height: 39px;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &-prev {
      transform: rotate(180deg);
    }
  }
  &__pic {
    position: relative;
    height: 200px;
    @include lg {
      height: 320px;
    }
    img {
      width: auto;
      max-width: 145px;
      height: 100%;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
    @include lg {
      font-size: 22px;
      line-height: 28px;
    }
    &:deep(ul) {
      padding-left: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &:deep(p:first-child) {
      display: inline;
    }
    &:deep(p) {
      margin-top: 10px;
    }
  }
  &__number {
    color: var(--colorPink);
    margin-right: 4px;
  }
}
</style>

<style lang="scss">
.step-item {
  &__pic {
    .swiper {
      height: 100%;
      &-slide {
        padding-bottom: 0 !important;
      }
    }
  }
}
</style>
