<template>
  <iframe
    id="map"
    class="map"
    src="https://msk.tele2.ru/coverage?mode=webView"
  ></iframe>
</template>

<script>
export default {
  name: "SectionMap",
};
</script>

<style lang="scss" scoped>
.map {
  overflow: hidden;
  width: 100%;
  min-height: 660px;
  border: none;
  display: block;
}
</style>
