<template>
  <nav class="nav">
    <div class="nav__inner">
      <ul>
        <li v-for="(element, index) of list" :key="index">
          <button type="button" @click="scroll(element.section)">
            <div v-html="element.name"></div>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HeaderNav",
  computed: {
    ...mapState({
      list: (state) => state.nav.list,
    }),
  },
};
</script>

<style lang="scss" scoped>
.nav {
  padding: 15px var(--widthGutter) 0 var(--widthGutter);
  background: #ffffff;
  @include lg {
    padding: 15px 0 0 0;
  }
  &__inner {
    width: 100%;
    // max-width: var(--widthContainer);
    margin: 0 auto;
    @include lg {
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      display: grid;
    }
  }
  ul {
    @include reset-list;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -8px;
    @include lg {
      justify-content: initial;
      margin: 0 auto;
    }
    li {
      margin: 0 8px;
      font-weight: 400;
      font-size: 14px;
      color: #7c8792;
      padding-bottom: 14px;
      position: relative;
      transition: all 0.3s;
      @include lg {
        scroll-snap-align: center;
      }
      &.active {
        color: var(--colorDarkSecond);
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: var(--colorDarkSecond);
          bottom: 0;
          left: 0px;
        }
      }
      button {
        @include reset-button;
        @include lg {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
