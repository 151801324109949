export default {
  namespaced: true,
  state: {
    selfReg: {
      title: `SIM-карты Tele2 с саморегистрацией \nв приложении «Мой Tele2»`,
    },
  },
  mutations: {
    setEsim(state, value) {
      state.esim = value;
    },
  },
};
