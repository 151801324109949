export default {
  namespaced: true,
  state: {
    apps: {
      title1: `Приложения Tele2`,
      title2: `Шаги по активации приложения Appseller`,
    },
  },
  mutations: {
    setEsim(state, value) {
      state.esim = value;
    },
  },
};
