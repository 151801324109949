<template>
  <div id="offers" class="offers">
    <div class="offers-head">
      <h2>
        {{ offers.title }}
      </h2>
      <h3>{{ offers.subtitle }}</h3>
      <div class="descCont">
        <p class="desc">
          {{ offers.subtitle2 }}
        </p>
        <p class="desc small" v-html="offers.subtitle3"></p>
      </div>
      <img v-if="offers.image" :src="mediaUrl(offers.image)" alt="" />
    </div>
    <div class="offers-swiper-wrapper">
      <swiper
        :modules="modules"
        :slides-per-view="'auto'"
        :slidesOffsetBefore="breakpointsMobile.includes(screenSize) ? 15 : 0"
        :slidesOffsetAfter="breakpointsMobile.includes(screenSize) ? 15 : 0"
        :space-between="16"
        :pagination="breakpointsMobile.includes(screenSize)"
        @swiper="onSwiper"
        class="offers-swiper"
      >
        <swiper-slide v-for="(item, key) of offers.subscriptions" :key="key">
          <offer-item
            :mix="item"
            :collapsed1="collapsed1"
            :collapsed2="collapsed2"
            @changeCollapsed1="collapsed1 = !collapsed1"
            @changeCollapsed2="collapsed2 = !collapsed2"
            :class="{ black: offers.subscriptions.length - 1 === key }"
          />
        </swiper-slide>
      </swiper>
    </div>
    <div class="offers-list">
      <div class="collapse" :class="{ collapsed: collapsed }">
        <div class="collapse-head" @click="collapsed = !collapsed">
          <div class="collapse-head__title">Как подключить MiXX</div>
          <div class="collapse-head__caret"></div>
        </div>
        <div class="collapse-body">
          <div v-html="offers.how_to_activation" class="activation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import OfferItem from "./OfferItem.vue";
import { mapState } from "vuex";

import { mediaUrl } from "@/utils";

export default {
  components: {
    Swiper,
    SwiperSlide,
    OfferItem,
  },
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  data() {
    return {
      collapsed: false,
      collapsed1: false,
      collapsed2: false,
    };
  },
  computed: {
    ...mapState({
      offers: (state) => state.promo.promo,
    }),
  },
  setup() {
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };

    return {
      mySwiper,
      onSwiper,
      modules: [Pagination],
    };
  },
};
</script>

<style lang="scss">
.offers {
  .activation {
    a {
      color: #ff59a3;
      text-decoration: underline;
    }
    ul {
      padding-left: 16px;
    }
    @include lg {
      ul {
        margin: 0;
        padding-left: 0;
        li {
          margin-bottom: 8px;
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.offers {
  background: #ff59a3;
  padding: 0 var(--widthGutter);
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  @include lg {
    background: #ec65a1;
  }
  &-head {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto auto;
    gap: 10px;
    margin-bottom: 18px;
    align-items: center;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto 40px auto;
      gap: 16px;
    }
    @include md {
      grid-template-columns: 1fr fit-content(100%);
    }
    > h2 {
      grid-column: 1;
      grid-row: 1;
      margin-bottom: 0;
      @include lg {
        font-weight: 700;
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 8px;
      }
    }
    > h3 {
      grid-column: 1;
      grid-row: 2;
      color: #fff;
      @include lg {
        font-weight: 700;
        font-size: 72px;
        line-height: 88px;
      }
    }
    .descCont {
      grid-column: 1;
      grid-row: 3;
    }
    p {
      margin: 0;
      @include lg {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
      }
    }
    img {
      grid-column: 2;
      grid-row-start: 1;
      grid-row-end: 4;
      width: 200px;
      display: none;
      @include md {
        display: block;
      }
    }
    .desc.small {
      margin-top: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1f2229;
      opacity: 0.5;
    }
    // .offers-price {
    //   grid-column: center / last;
    //   grid-row: first / last;
    // }
  }
  &-price {
    color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='110' height='76' viewBox='0 0 110 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h110v56L55 76 0 56V0Z' fill='%231F2229'/%3E%3C/svg%3E");
    width: 110px;
    height: 76px;
    text-align: center;
    padding-top: 8px;
    @include lg {
      grid-row: first/last;
      grid-column: last;
      width: 280px;
      height: 194px;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 20px;
    }
    &-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 10px;
      }
    }
    strong {
      font-family: var(--fontStencil);
      font-size: 32px;
      line-height: 36px;
      @include lg {
        font-size: 82px;
        line-height: 92px;
      }
    }
    span {
      display: block;
      font-family: var(--fontSerif);
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      @include lg {
        font-size: 30px;
        line-height: 43px;
      }
    }
  }
  &-list {
    display: grid;
    gap: 8px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
      border-radius: 16px;
      padding: 0 60px 0 56px;
    }
  }
  .activation {
    @include lg {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 200px;
      margin-bottom: 40px;
    }
  }
}
.offers-swiper {
  padding-bottom: 16px;
  margin-bottom: 24px;
  &-wrapper {
    width: calc(100vw - 30px);
    margin: 0 -15px;
    @include lg {
      display: flex;
      justify-content: center;
      padding: 0 15px 0 15px;
      :deep(.swiper-wrapper) {
        max-width: var(--widthContainer);
      }
    }
    @include xl {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
  .swiper-slide {
    width: 285px;
    @include lg {
      width: 266px;
    }
  }
}
</style>
<style lang="scss">
.offers-swiper {
  .swiper-pagination {
    &-bullet {
      border: 1px solid #ffffff !important;
      width: 8px !important;
      height: 8px !important;
      &-active {
        background: #fff !important;
      }
    }
  }
}
</style>
