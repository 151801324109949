<template>
  <div id="apps" class="apps">
    <div class="apps-section">
      <div class="apps-head">
        <h2>{{ apps.title1 }}</h2>
      </div>
      <template v-if="breakpointsMobile.includes(screenSize)">
        <div class="apps-content">
          <div class="apps-widget">
            <div class="apps-widget__preview">
              <img src="/static/demo/4.png" alt="" />
            </div>
            <div class="apps-widget__content">
              <div class="apps-widget__title">
                Личный&nbsp;кабинет «Мой&nbsp;Tele2»
              </div>
              <div class="apps-widget__desc">Приложение для клиента</div>
            </div>
          </div>
          <QRLink
            src="/static/demo/qr-app-1.svg"
            link="https://onelink.to/ts5aby"
          />
        </div>
        <div class="apps-list">
          <div class="collapse" :class="{ collapsed: collapsedApp1Step1 }">
            <div
              class="collapse-head"
              @click="collapsedApp1Step1 = !collapsedApp1Step1"
            >
              <div class="collapse-head__title">
                Лучший способ управлять услугами и счетом для абонентов Tele2
              </div>
              <div class="collapse-head__caret"></div>
            </div>
            <div class="collapse-body">
              <div class="collapse-params">
                <div class="collapse-params__item">
                  <div class="collapse-params__content">
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>подключение к Tele2 с саморегистрацией;</li>
                        <li>подключение eSIM;</li>
                        <li>проверка остатков гигабайтов и минут;</li>
                        <li>настройка тарифа;</li>
                        <li>
                          подключение или отключение дополнительных услуг;
                        </li>
                        <li>
                          возможность делиться гигабайтами с абонентами Tele2;
                        </li>
                        <li>возможность продавать и покупать минуты и ГБ;</li>
                        <li>
                          удобный виджет для отслеживания остатков минут,
                          гигабайтов и баланса.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="collapse" :class="{ collapsed: collapsedApp1Step2 }">
            <div
              class="collapse-head"
              @click="collapsedApp1Step2 = !collapsedApp1Step2"
            >
              <div class="collapse-head__title">Управление счетом</div>
              <div class="collapse-head__caret"></div>
            </div>
            <div class="collapse-body">
              <div class="collapse-params">
                <div class="collapse-params__item">
                  <div class="collapse-params__content">
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>
                          пополнение баланса с помощью бесконтактных платежей
                          или банковских карт;
                        </li>
                        <li>
                          установка платежа с привязанной банковской карты;
                        </li>
                        <li>использование обещанного платежа;</li>
                        <li>
                          просмотр статистики расходов и заказа детализации;
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="collapse" :class="{ collapsed: collapsedApp1Step3 }">
            <div
              class="collapse-head"
              @click="collapsedApp1Step3 = !collapsedApp1Step3"
            >
              <div class="collapse-head__title">Бонусы</div>
              <div class="collapse-head__caret"></div>
            </div>
            <div class="collapse-body">
              <div class="collapse-params">
                <div class="collapse-params__item">
                  <div class="collapse-params__content">
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>
                          скидки, кешбэки и подарки от наших партнеров по
                          программе «Больше»;
                        </li>
                        <li>
                          уникальные предложения и приглашения на интересные
                          мероприятия.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="apps-content apps-content_type_extended">
          <div class="apps-widget">
            <div class="apps-widget__preview">
              <img src="/static/demo/5.png" alt="" />
            </div>
            <div class="apps-widget__content">
              <div class="apps-widget__title">APPSELLER</div>
              <div class="apps-widget__desc">Приложение для продавца</div>
            </div>
          </div>
          <QRLink
            src="/static/demo/qr-app-2.png"
            link="http://onelink.to/twcjcq"
          />
          <div class="apps-content__desc">
            <strong
              >Быстро и просто оформить подключение клиента со своего смартфона
              тебе позволит приложение AppSeller.</strong
            >
          </div>
          <div class="apps-list">
            <div class="collapse" :class="{ collapsed: collapsedApp2Step1 }">
              <div
                class="collapse-head"
                @click="collapsedApp2Step1 = !collapsedApp2Step1"
              >
                <div class="collapse-head__title">Преимущества AppSeller</div>
                <div class="collapse-head__caret"></div>
              </div>
              <div class="collapse-body">
                <div class="collapse-params">
                  <div class="collapse-params__item">
                    <div class="collapse-params__content">
                      <div
                        class="collapse-params__desc collapse-params__desc_only_list"
                      >
                        <ul>
                          <li>
                            при оформлении договора не нужно использовать
                            бумажные РФА, хранить их на торговой точке и
                            передавать представителям Tele2;
                          </li>
                          <li>
                            простые и последовательные шаги при оформлении
                            договора;
                          </li>
                          <li>процесс занимает всего несколько минут;</li>
                          <li>
                            безопасность личных данных: данные передаются
                            напрямую в Tele2;
                          </li>
                          <li>
                            возможность решения возникающих вопросов на горячей
                            линии;
                          </li>
                          <li>
                            автоматическое зачисление дилер-бонуса, не нужно
                            набирать дополнительные команды.
                          </li>
                          <li>
                            Возможность оформить заявку на перенос номера
                            клиента в сеть Tele2.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="apps-content__desc">
            В приложении можно отслеживать статистику своих продаж, узнавать
            новости и получать подсказки по тарифам.
          </div>
        </div>
      </template>
      <template v-else>
        <div class="apps-list">
          <div class="collapse" :class="{ collapsed: collapsedApp1 }">
            <div class="collapse-head" @click="collapsedApp1 = !collapsedApp1">
              <div class="collapse-head__title">Мой Tele2</div>
              <div class="collapse-head__caret"></div>
            </div>
            <div class="collapse-body">
              <div class="collapse-params">
                <div class="apps-content">
                  <div class="apps-widget">
                    <div class="apps-widget__preview">
                      <img src="/static/demo/4.png" alt="" />
                    </div>
                    <div class="apps-widget__content">
                      <div class="apps-widget__title">
                        Личный&nbsp;кабинет «Мой&nbsp;Tele2»
                      </div>
                      <div class="apps-widget__desc">
                        Приложение для клиента
                      </div>
                    </div>
                  </div>
                  <QRLink
                    src="/static/demo/qr-app-1.svg"
                    link="https://onelink.to/ts5aby"
                  />
                </div>
                <div class="collapse-params__content apps-params">
                  <div class="apps-params__item">
                    <div class="collapse-params__title">
                      Лучший способ управлять услугами и счетом для абонентов
                      Tele2
                    </div>
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>подключение к Tele2 с саморегистрацией;</li>
                        <li>подключение eSIM;</li>
                        <li>проверка остатков гигабайтов и минут;</li>
                        <li>настройка тарифа;</li>
                        <li>
                          подключение или отключение дополнительных услуг;
                        </li>
                        <li>
                          возможность делиться гигабайтами с абонентами Tele2;
                        </li>
                        <li>возможность продавать и покупать минуты и ГБ;</li>
                        <li>
                          удобный виджет для отслеживания остатков минут,
                          гигабайтов и баланса.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="apps-params__item">
                    <div class="collapse-params__title">Управление счетом</div>
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>
                          пополнение баланса с помощью бесконтактных платежей
                          или банковских карт;
                        </li>
                        <li>
                          установка платежа с привязанной банковской карты;
                        </li>
                        <li>использование обещанного платежа;</li>
                        <li>
                          просмотр статистики расходов и заказа детализации;
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="apps-params__item">
                    <div class="collapse-params__title">Бонусы</div>
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>
                          скидки, кешбэки и подарки от наших партнеров по
                          программе «Больше»;
                        </li>
                        <li>
                          уникальные предложения и приглашения на интересные
                          мероприятия.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="collapse" :class="{ collapsed: collapsedApp2 }">
            <div class="collapse-head" @click="collapsedApp2 = !collapsedApp2">
              <div class="collapse-head__title">Appseller</div>
              <div class="collapse-head__caret"></div>
            </div>
            <div class="collapse-body">
              <div class="collapse-params">
                <div class="apps-content">
                  <div class="apps-widget">
                    <div class="apps-widget__preview">
                      <img src="/static/demo/4.png" alt="" />
                    </div>
                    <div class="apps-widget__content">
                      <div class="apps-widget__title">APPSELLER</div>
                      <div class="apps-widget__desc">
                        Приложение для продавца
                      </div>
                    </div>
                  </div>
                  <div class="apps-content__desc">
                    <strong
                      >Быстро и просто оформить подключение клиента со своего
                      смартфона тебе позволит приложение AppSeller.</strong
                    >
                  </div>
                  <QRLink
                    src="/static/demo/qr-app-2.png"
                    link="http://onelink.to/twcjcq"
                  />
                </div>
                <div class="collapse-params__content apps-params">
                  <div class="apps-params__item">
                    <div class="collapse-params__title">
                      Преимущества AppSeller
                    </div>
                    <div
                      class="collapse-params__desc collapse-params__desc_only_list"
                    >
                      <ul>
                        <li>
                          при оформлении договора не нужно использовать бумажные
                          РФА, хранить их на торговой точке и передавать
                          представителям Tele2;
                        </li>
                        <li>
                          простые и последовательные шаги при оформлении
                          договора;
                        </li>
                        <li>процесс занимает всего несколько минут;</li>
                        <li>
                          безопасность личных данных: данные передаются напрямую
                          в Tele2;
                        </li>
                        <li>
                          возможность решения возникающих вопросов на горячей
                          линии;
                        </li>
                        <li>
                          автоматическое зачисление дилер-бонуса, не нужно
                          набирать дополнительные команды.
                        </li>
                        <li>
                          Возможность оформить заявку на перенос номера клиента
                          в сеть Tele2.
                        </li>
                      </ul>
                    </div>
                    <div class="collapse-params__caption">
                      В приложении можно отслеживать статистику своих продаж,
                      узнавать новости и получать подсказки по тарифам.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="apps-section">
      <div class="apps-head">
        <h2>{{ apps.title2 }}</h2>
      </div>
      <div class="apps-list">
        <div class="collapse" :class="{ collapsed: collapsedStep1 }">
          <div class="collapse-head" @click="collapsedStep1 = !collapsedStep1">
            <div class="collapse-head__title">Регистрация в приложении</div>
            <div class="collapse-head__caret"></div>
          </div>
          <div class="collapse-body">
            <div class="collapse-content">
              <div class="apps-swiper">
                <swiper
                  :modules="modules"
                  :slides-per-view="'auto'"
                  :space-between="16"
                  :pagination="true"
                  @swiper="onSwiper"
                >
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/1/image 127.png']"
                      number="ШАГ 1:"
                      text="Зайдите в мобильное приложение. Откроется экран загрузки."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/1/image 128.png']"
                      number="ШАГ 2:"
                      text="Введите номер, с которым хотите зарегистрироваться в приложении. Нажмите «Далее»."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/1/image 129.png']"
                      number="ШАГ 3:"
                      text="Введите код из SMS."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/1/image 130.png']"
                      number="ШАГ 4:"
                      text="Регистрация возможна с любым номером. Если вы абонент Tele2, то вас ждут дополнительные бонусы."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/1/image 131.png',
                        '/static/demo/apps/1/image 162.png',
                      ]"
                      number="ШАГ 5:"
                      text="Заполните анкету продавца
                      <ul><li>Фамилия, имя, отчество</li><li>Код точки продаж;</li><li>Контактный номер телефона;</li><li>E-mail (не обязательное поле)</li><li>Приложите своё фото по желанию</li><li>Подпишите анкету. Распишитесь пальцем внутри рамки. В дальнейшем она будет добавляться при подписании документов.</li></ul>"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/1/image 133.png']"
                      number="ШАГ 6:"
                      text="При нажатии на кнопку «Отправить анкету» отобразиться экран, сообщающий об успешной регистрации."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/1/image 134.png',
                        '/static/demo/apps/1/image 160.png',
                        '/static/demo/apps/1/image 161.png',
                      ]"
                      number="ШАГ 7:"
                      text="<p>Нажмите «Авторизоваться», произойдет переход к заданию входов доступа в приложении.
В дальнейшем для авторизации можно будет использовать: Face ID, Touch ID, код доступа или пароль.</p>
<p><strong>Теперь вы готовы подключить клиента!</strong></p>"
                    />
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <div class="collapse" :class="{ collapsed: collapsedStep2 }">
          <div class="collapse-head" @click="collapsedStep2 = !collapsedStep2">
            <div class="collapse-head__title">Продажа SIM-карты</div>
            <div class="collapse-head__caret"></div>
          </div>
          <div class="collapse-body">
            <div class="collapse-content">
              <div class="apps-swiper">
                <swiper
                  :modules="modules"
                  :slides-per-view="'auto'"
                  :space-between="16"
                  :pagination="true"
                  @swiper="onSwiper"
                >
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 137.png']"
                      number="ШАГ 1:"
                      text="Откройте приложение и выберите «Продать SIM-карту» или «Перевести в Tele2» в зависимости от запроса клиента."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 138.png']"
                      number="ШАГ 2:"
                      text="Откроется экран «Сканирование SIM». Если сканирование не произошло, введите данные SIM вручную (номер + PUK)."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 139.png']"
                      number="ШАГ 3:"
                      text="<p>На экране отобразится номер абонента, номер карты (ICC), тариф, баланс.</p>
<p>Нажмите «Продолжить».</p>"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 140.png']"
                      number="ШАГ 4:"
                      text="Если нужно оформить перенос номера, введите номер, который абонент хочет перенести в сеть Tele2."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/2/image 141.png',
                        '/static/demo/apps/2/image 163.png',
                      ]"
                      number="ШАГ 4.1:"
                      text="Если на номере нет запретов, то появится выбор даты переноса. Она не может быть меньше 8 дней от текущего дня. По-умолчанию будет выбрана ближайшая возможная дата и время переноса.
При желании абонента можно выбрать другой день и время (в пределах 180 дней от текущей даты)."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 143.png']"
                      number="ШАГ 4.2:"
                      text="Клиент будет получать SMS о ходе переноса на свой переносимый номер. Если абонент хочет получать SMS и на другой номер, его необходимо указать на этом экране либо просто нажать «Далее»."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 144.png']"
                      number="ШАГ 5:"
                      text="Следующий шаг – проверка документа удостоверяющего личность и оформление SIM-карты."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/2/image 145.png',
                        '/static/demo/apps/2/image 164.png',
                      ]"
                      number="ШАГ 6:"
                      text="<p>Распознайте первую страницу паспорта (данные клиента). Проверьте корректность данных. Если поля не распознались, внесите их вручную.</p>
<p>Если подключение происходит не по паспорту гражданина РФ, выберите необходимый документ в поле «Документы»</p>"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 147.png']"
                      number="ШАГ 7:"
                      text="Для граждан РФ укажите адрес регистрации. Его можно отсканировать, нажав «Начать сканирование», или заполнить вручную."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/2/image 148.png']"
                      number="ШАГ 8:"
                      text="Передайте смартфон клиенту, чтобы он сам указал электронный адрес. На него будет оправлен комплект подписанных документов.
Жмите «Продолжить»."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/2/image 149.png',
                        '/static/demo/apps/2/image 165.png',
                      ]"
                      number="ШАГ 9:"
                      text="Формируются документы:
<ul>
<li>Согласие на использование аналога подписи</li>
<li>Заявление о перенесении номера</li>
<li>Договор на оказание услуг связи;</li>
<li>Дополнительные условия.</li>
</ul>
Документы можно открыть для ознакомления нажав на иконку стрелки справа."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/2/image 151.png',
                        '/static/demo/apps/2/image 166.png',
                        '/static/demo/apps/2/image 167.png',
                      ]"
                      number="ШАГ 10:"
                      text="Если абонент согласен со всеми условиями, жмите «Продолжить». Дайте клиенту подписать Договор графической подписью и нажмите «Подписать». Ваша подпись проставится автоматически, и документы уходят на формирование. При успешной продаже отображается соответствующий экран. В случае ошибки на экране появятся рекомендациями с дальнейшими действиями"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      number="ЗАВЕРШЕНИЕ:"
                      text="<ul>
<li>Примите от клиента деньги за SIM-карту</li>
<li>Передайте ему SIM-карту и чек (либо бланк строгой отчетности)</li>
<li>SIM-карта активируется автоматически</li>
</ul>"
                    />
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <div class="collapse" :class="{ collapsed: collapsedStep3 }">
          <div class="collapse-head" @click="collapsedStep3 = !collapsedStep3">
            <div class="collapse-head__title">
              Что еще может Tele2 AppSeller
            </div>
            <div class="collapse-head__caret"></div>
          </div>
          <div class="collapse-body">
            <div class="collapse-content">
              <div class="apps-swiper">
                <swiper
                  :modules="modules"
                  :slides-per-view="'auto'"
                  :space-between="16"
                  :pagination="true"
                  @swiper="onSwiper"
                >
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/3/image 154.png']"
                      number="НОВОСТИ:"
                      text="Все новости Tele2 можно узнать прямо в AppSeller. Новости представлены в виде ленты с фильтрами и возможностью поставить «лайк» самым полезным статьям."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/3/image 155.png']"
                      number="ТАРИФЫ:"
                      text="При нажатии на кнопку «Подобрать тариф на сайте Tele2» внутри приложения открывается раздел «Тарифы» на сайте Tele2. Можно быстро почитать условия того тарифа, который вы хотите продать."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/3/image 156.png']"
                      number="СТАТИСТИКА ПРОДАЖ:"
                      text="Нажмите на кнопку «Вся история» на главной странице и вы увидите статистику продаж по дням с возможностью гибко фильтровать список."
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="['/static/demo/apps/3/image 157.png']"
                      number="ПОРТАЛ ОБУЧЕНИЯ:"
                      text="<p>Портал обучения ABC находится во вкладке «Развитие».</p>
<p>На нем вы найдете мотивационные программы и обучающие курсы.</p>"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      :img="[
                        '/static/demo/apps/3/image 158.png',
                        '/static/demo/apps/3/image 168.png',
                      ]"
                      number="ПОРТАЛ ОБУЧЕНИЯ:"
                      text="При первом входе на портал обучения необходимо ввести логин и пароль от учетной записи, если она есть. Либо зарегистрироваться, заполнив небольшую анкету. После проверки вашей заявки, вы получите email с подтверждением регистрации"
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <ContentStepItem
                      text="<p>Есть вопросы по работе приложения? Задавайте их в чате оператору в разделе «Поддержка».</p>
<p>Функционал приложения будет расширяться, и вы сможете работать с покупателем еще качественней и быстрее. Ждите новостей в Tele2 AppSeller!</p>"
                    />
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import { mediaUrl } from "@/utils";
import QRLink from "./QRLink.vue";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import ContentStepItem from "./ContentStepItem.vue";

export default {
  components: {
    QRLink,
    Swiper,
    SwiperSlide,
    ContentStepItem,
  },
  setup() {
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };

    return {
      mySwiper,
      onSwiper,
      modules: [Pagination],
    };
  },
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  data() {
    return {
      collapsedApp1: false,
      collapsedApp1Step1: false,
      collapsedApp1Step2: false,
      collapsedApp1Step3: false,
      collapsedApp2: false,
      collapsedApp2Step1: false,
      collapsedStep1: false,
      collapsedStep2: false,
      collapsedStep3: false,
    };
  },
  computed: {
    ...mapState({
      apps: (state) => state.apps.apps,
    }),
  },
};
</script>

<style lang="scss">
.apps {
  ol {
    margin-left: 24px;
    gap: 8px;

    li {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
</style>

<style lang="scss" scoped>
.apps {
  padding: 0 var(--widthGutter);
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  @include lg {
    display: grid;
    gap: 80px;
  }
  @include lg {
    padding-top: 72px;
  }

  &-head {
    display: grid;
    gap: 5px;
    margin-bottom: 18px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto 40px auto;
      gap: 16px;
      align-items: flex-start;
    }

    > h2 {
      margin-bottom: 0;
      @include lg {
        font-weight: 700;
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 8px;
      }
    }

    .apps-head-text {
      grid-column: first / last;
      font-size: 14px;
      @include lg {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }

    p {
      margin: 0;
      @include lg {
        font-size: 22px;
        line-height: 26px;
      }
    }

    img {
      @include lg {
        grid-column: last;
        grid-row: first / last;
        width: 280px;
        height: 290px;
        margin-top: -58px;
      }
    }
  }

  &-list {
    display: grid;
    gap: 8px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
      border-radius: 16px;
      padding: 0 60px 0 56px;
    }
  }

  &-content {
    display: grid;
    gap: 32px;
    margin-top: 32px;
    margin-bottom: 32px;
    align-self: start;
    @include lg {
      margin-top: 0;
      margin-bottom: 0;
      gap: 40px;
      .qr-link {
        margin: 0;
      }
    }
    &_type_extended {
      margin-top: 40px;
      .apps-list {
        margin: -12px 0;
      }
    }
    &__desc {
      font-size: 16px;
      @include lg {
        font-size: 20px;
        max-width: 440px;
      }
    }
  }

  &-params {
    display: grid;
    gap: 24px;
    align-self: start;
    &__item {
      display: grid;
      gap: 12px;
      align-self: start;
    }
  }

  &-widget {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 20px;
    @include lg {
      grid-template-columns: 120px 1fr;
      gap: 28px;
    }
    &__preview {
      img {
        display: block;
      }
    }
    &__content {
      display: grid;
      gap: 8px;
      align-self: center;
    }
    &__title {
      font-family: var(--fontSerif);
      font-weight: 700;
      font-size: 24px;
    }
    &__desc {
      font-size: 16px;
    }
  }
}

@include lg {
  .collapse {
    &-body {
      padding: 0 0 40px 0;
    }

    &-params {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      &--grid1 {
        grid-template-columns: 1fr;

        .collapse-params__item {
          display: block;
        }

        .collapse-params__desc {
          display: grid;
          grid-template-columns: [first] 240px 241px [c] 1fr [last];
          align-items: center;

          p {
            grid-column: first/last;
            max-width: 768px;
            margin-bottom: 40px;
          }

          img {
            margin: 0;
          }
        }
      }

      &__grid3 {
        grid-template-columns: 1fr 1fr 1fr;

        .collapse-params__desc {
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &__grid4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &__item {
        padding-bottom: 0;

        ul {
          margin-left: 0;
        }
      }

      &__desc p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-steps {
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;

      &__body {
        max-width: 225px;
        border-top: none;
      }

      &__item {
        min-width: 200px;
        display: flex;
        align-items: flex-start;
      }

      &__caption {
        max-width: 234px;
        margin-left: 20px;
        margin-top: 15px;
        margin-bottom: 0;
        min-width: 45%;
      }

      &__number {
        font-weight: 700;
        font-size: 35px;
        line-height: 140%;
        margin-top: -8px;
      }

      &__desc {
        font-size: 14px;
      }
    }
  }
}

.apps-swiper {
  display: grid;
  // &-wrapper {
  //   width: 100%;
  //   @include lg {
  //     max-width: var(--widthContainer);
  //   }
  // }
  .swiper {
    width: 100%;
    &-slide {
      width: auto;
    }
  }
}
</style>

<style lang="scss">
.apps-swiper {
  padding-bottom: 18px;
  @include lg {
    padding-bottom: 40px;
  }
  .swiper-slide {
    padding-bottom: 24px;
    @include lg {
      padding-bottom: 40px;
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100% !important;
    &-bullet {
      @include lg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
