<template>
  <div class="modal" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <transition name="fade-out">
        <div v-if="show" class="modal-content">
          <div class="modal__head">
            <button class="modal__close" @click="close()"></button>
          </div>
          <div class="modal__body">
            <div class="modal__title">Последние обновления</div>
            <div class="notify-list">
              <div class="notify-item" v-for="item in messages" :key="item.id">
                <div class="notify-item__date">{{ item.date }}</div>
                <div class="notify-item__text">
                  {{ item.text }}
                </div>
                <button class="notify-item__button" @click="see(item)">
                  Смотреть
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition name="fade">
    <div v-if="show" class="modal-backdrop"></div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { divide, highlight } from "@/utils";

export default {
  name: "ModalNotify",
  emits: ["close"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      messages: (state) => state.notifications.notifications,
    }),
  },
  created() {
    document.body.classList.add("modal-open");
    document.addEventListener("keyup", this.keyPressEscape);
  },
  mounted() {
    this.show = true;
    Cookies.set("notifications", JSON.stringify(this.messages));
  },
  unmounted() {
    document.body.classList.remove("modal-open");
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  methods: {
    keyPressEscape(e) {
      if (e.key === "Escape") {
        this.close();
      }
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
    see(item) {
      this.show = false;
      this.$emit("close");
      this.scroll(`#${item.url}`);
      highlight(item.url, divide(item.modified_text));
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    min-height: 100%;
    margin: 0 auto;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    padding: 0 15px;
    padding-bottom: 24px;
    @include lg {
      background-color: rgb(256, 256, 256, 0.95);
    }
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
  }

  &__head {
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%231F2229'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    width: 45px;
    height: 45px;
    margin: 0 -12px;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
      cursor: default;
    }
  }

  &__title {
    font-family: var(--fontSerif);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    @include lg {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 25px;
      margin-top: -22px;
    }
  }

  &__body {
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      width: 100%;
    }
  }
}

.notify {
  &-list {
    display: grid;
    gap: 16px;
    margin-top: 24px;
    @include lg {
      grid-template-columns: repeat(auto-fill, 252px);
      gap: 36px;
    }
  }

  &-item {
    display: grid;
    gap: 8px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
    border-radius: 8px;
    padding: 16px;
    @include lg {
      max-width: 252px;
      height: 161px;
    }

    &__date {
      font-size: 14px;
      line-height: 18px;
      opacity: 0.4;
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
    }

    &__button {
      @include reset-button;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
    }
  }
}
</style>
