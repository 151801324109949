<template>
  <div v-show="regionName" class="region">
    <div class="region__inner">
      <div class="region__group">
        <div class="region__text">Ваш регион — {{ regionName }}?</div>
        <div class="region__buttons">
          <button @click="confirm(region.siteId)" class="region__button">
            {{ breakpointsDesktop.includes(screenSize) ? "Верно" : "Да" }}
          </button>
          <button
            class="region__button region__button_second"
            @click="$emit('showLocation')"
          >
            {{
              breakpointsDesktop.includes(screenSize)
                ? "Изменить регион"
                : "Нет"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    location: Boolean,
  },
  mounted() {
    document.body.classList.add("region-confirm");
  },
  unmounted() {
    document.body.classList.remove("region-confirm");
  },
  computed: {
    ...mapState({
      region: (state) => state.api.regionConfirm,
    }),
    regionName() {
      return this.region?.name;
    },
  },
  methods: {
    ...mapMutations({
      setRegion: "region/setRegion",
    }),
    confirm(id) {
      this.setRegion(id);
    },
  },
};
</script>

<style lang="scss">
.region-confirm {
  padding-top: 45px;
}
</style>
<style lang="scss" scoped>
.region {
  background: #ff59a3;
  color: #fff;
  padding: 7px var(--widthGutter);
  &__inner {
    max-width: var(--widthContainer);
    margin: 0 auto;
  }
  &__group {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
    @include lg {
      grid-template-columns: auto auto;
      justify-content: flex-end;
      gap: 20px;
    }
  }
  &__text {
    font-size: 12px;
    @include lg {
      font-size: 14px;
    }
  }
  &__buttons {
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
    @include lg {
      gap: 10px;
    }
  }
  &__button {
    @include reset-button;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    color: #1f2229;
    height: 32px;
    min-width: 56px;
    padding: 0 10px;
    @include lg {
      border-radius: 0;
      padding: 5px 20px;
      min-width: 100px;
    }
    &:hover {
      opacity: 0.9;
    }
    &_second {
      background: rgba(255, 255, 255, 0.4);
      color: #fff;
    }
  }
}
</style>
