<template>
  <div class="join-item">
    <div class="join-item-tag">Шаг {{ item.step }}</div>
    <div class="collapse grey" :class="{ collapsed: collapsed }">
      <div
        class="collapse-head"
        @click="item.title ? (collapsed = !collapsed) : null"
      >
        <div class="collapse-head__title">
          {{ item.text }}
        </div>
        <div class="collapse-head__caret" v-if="item.title"></div>
      </div>
      <div class="collapse-body">
        <div class="collapse-params">
          <div class="collapse-params__content">
            <div class="collapse-params__title" v-html="item.title"></div>
            <div class="collapse-params__desc" v-html="item.explanation"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinItem",
  props: {
    item: Object,
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style lang="scss">
.join {
  @include lg {
    ul {
      grid-template-columns: 1fr 1fr !important;
      column-gap: 32px !important;
      row-gap: 16px !important;

      li {
        font-size: 14px !important;

        img {
          margin-right: 5px !important;
        }
      }
    }
    .collapse-params__content {
      gap: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.join {
  &-item {
    &-tag {
      background: #ff59a3;
      border-radius: 5px;
      padding: 2px 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      display: inline-block;
      margin-top: 8px;
    }
  }

  .collapse-params__desc {
    ul {
      display: grid;

      li {
        &:nth-child(3) {
          grid-row-start: 2;
          grid-row-end: 2;
          @include lg {
            grid-row-start: auto;
            grid-row-end: auto;
          }
        }

        &:nth-child(5) {
          grid-row-start: 3;
          grid-row-end: 3;
          @include lg {
            grid-row-start: auto;
            grid-row-end: auto;
          }
        }
      }
    }
  }

  @include lg {
    .join-item {
      &:last-child {
        .collapse {
          border-bottom: none;
        }
      }

      &-tag {
        padding: 8px 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-top: 18px;
      }
    }
    .collapse {
      &-head {
        padding: 16px 0 31px 0;

        &__title {
          font-size: 22px;
          line-height: 26px;
        }
      }

      &-body {
        border-bottom: none;
      }

      &-params {
        padding: 24px;
        margin-top: -15px;

        &__subtitle {
          margin-bottom: 16px;
        }

        &__desc {
          ol {
            grid-gap: 8px;
          }

          ul {
            @include reset-list;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 32px;
            grid-row-gap: 16px;

            li {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: #ffffff;
              box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
              border-radius: 4px;
              overflow: hidden;

              img {
                width: 70px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
