<template>
  <div id="join" class="join">
    <div class="join-head">
      <h2>Подключение нового абонента</h2>
    </div>
    <div v-if="steps?.length" class="join-list">
      <JoinItem
        v-for="(item, index) in steps.slice(0, -1)"
        :item="item"
        :key="index"
        v-once
      />
      <div class="join-item">
        <div class="join-item-tag">Шаг 7</div>
        <div class="collapse" :class="{ collapsed: collapsed3 }">
          <div class="collapse-head" @click="collapsed3 = !collapsed3">
            <div class="collapse-head__title">
              Переход в Tele2 с сохранением своего номера
            </div>
            <div class="collapse-head__caret"></div>
          </div>
          <div class="collapse-body">
            <div class="collapse-params">
              <div class="collapse-params__content">
                <div class="collapse-params__title seventhStepTitle">
                  Перенос номера (MNP, mobile number portability)
                </div>
                <div class="collapse-params__desc">
                  <p>
                    Это возможность сменить оператора и получить более выгодные
                    условия, не меняя номер телефона. Это очень удобно — не
                    нужно сообщать
                    <br v-if="breakpointsDesktop.includes(screenSize)" />
                    всем знакомым и друзьям о новом номере телефона или бояться,
                    что нужный человек не сможет дозвониться!
                  </p>
                  <p>
                    <b
                      >Смело предлагайте клиентам данную услугу. Следуйте
                      простому алгоритму.</b
                    >
                  </p>
                  <div class="collapse-params__item">
                    <div class="collapse-params__icon">
                      <img src="@/assets/icons/Ellipse_129.svg" alt="" />
                      <span>1</span>
                    </div>
                    <div class="collapse-params__content">
                      <div class="collapse-params__title">
                        Уточните, есть ли у клиента номер другого оператора,
                        <br v-if="breakpointsDesktop.includes(screenSize)" />
                        и расскажите про возможность переноса номера в сеть
                        Tele2.
                      </div>
                    </div>
                  </div>
                  <div class="collapse-params__item">
                    <div class="collapse-params__icon">
                      <img src="@/assets/icons/Ellipse_129.svg" alt="" />
                      <span>2</span>
                    </div>
                    <div class="collapse-params__content">
                      <div class="collapse-params__title">
                        Проинформируйте клиента о сроках переноса номера,
                        озвучьте основные моменты.
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      Срок переноса для частных клиентов можно выбрать в
                      интервале от 8 дней до 6 месяцев после подачи заявления.
                    </li>
                    <li>
                      Условия обслуживания, которые были у оператора — донора,
                      не сохраняются.
                    </li>
                    <li>
                      Перенос номера между регионами, а также городского номера
                      невозможен.
                    </li>
                    <li>
                      Все задолженности перед текущим оператором должны быть
                      погашены.
                    </li>
                  </ul>
                  <div class="collapse-params__item">
                    <div class="collapse-params__icon">
                      <img src="@/assets/icons/Ellipse_129.svg" alt="" />
                      <span>3</span>
                    </div>
                    <div class="collapse-params__content">
                      <div class="collapse-params__title">
                        Отработайте возражения, используя преимущества тарифной
                        линейки Tele2.
                      </div>
                    </div>
                  </div>
                  <div class="collapse-params__item">
                    <div class="collapse-params__icon">
                      <img src="@/assets/icons/Ellipse_129.svg" alt="" />
                      <span>4</span>
                    </div>
                    <div class="collapse-params__content">
                      <div class="collapse-params__title">
                        Оформите перенос.
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      Распечатайте 2 заявления: распишитесь сами, отдайте на
                      подпись абоненту. При оформлении заявки в приложении
                      AppSeller клиенту достаточно подписать заявление в
                      цифровом формате.
                    </li>
                    <li>
                      Отсканируйте заявление, заархивируйте в формате ZIP и
                      приложите к заявке. При оформлении в приложении AppSeller
                      данные поступают в систему автоматически.
                    </li>
                    <li>
                      Подключите временный номер (стандартное подключение новой
                      SIM-карты).
                    </li>
                    <li>
                      Заполните заявку на перенос номера, оформите заявление и
                      внесите туда необходимые данные.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JoinItem
        id="social-tariff"
        v-for="(item, index) in steps.slice(-1)"
        :item="item"
        :key="index"
        v-once
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JoinItem from "@/components/JoinItem.vue";

export default {
  components: { JoinItem },
  computed: {
    ...mapState({
      steps: (state) => state.sub.sub,
    }),
  },
  data() {
    return {
      collapsed1: false,
      collapsed2: false,
      collapsed3: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.join {
  padding: 0 var(--widthGutter);
  padding-top: 48px;
  padding-bottom: 24px;
  background: #f3f5f6;
  @include lg {
    padding-top: 72px;
  }

  &-head {
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto 40px auto;
    }

    > h2 {
      grid-column: first / last;
      margin-top: 0;
      @include lg {
        font-weight: 700;
        font-size: 38px;
        line-height: 44px;
        margin: 0;
      }
    }
  }

  &-list {
    display: grid;
    gap: 8px;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
    }
  }

  &-item {
    border-bottom: 1px solid #e8e8ed;

    &-tag {
      background: #ff59a3;
      border-radius: 5px;
      padding: 2px 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      display: inline-block;
      margin-top: 8px;
    }
  }

  .collapse-head {
    background: #f3f5f6;
    padding-left: 0;
  }

  .collapse-body {
    padding-top: 1rem;
    border-radius: 8px;
    ol {
      padding-bottom: 1rem;
    }
  }

  .collapse-params {
    border-radius: 8px;
    @include lg {
      border-radius: 4px;
    }
  }

  .collapse-params__item {
    border: none;
    max-width: 260px;
    padding: 8px 0;
  }

  .collapse-params__item:first-of-type {
    padding-top: 0;
  }

  .collapse-params__title {
    display: flex;
    align-items: center;
    color: #ff59a3;
  }

  .collapse-params__subtitle {
    color: #ff59a3;
  }

  .seventhStepTitle {
    color: #ff59a3;
    font-size: 18px;
    line-height: 24px;
  }

  .join-item:last-of-type {
    .collapse-body {
      padding-bottom: 24px;

      @include lg {
        padding: 0;
      }
    }
    .collapse-params__content {
      gap: 11px;

      @include lg {
        gap: 16px;
      }
    }

    .collapse-params__item {
      padding: 0;
      margin-bottom: 16px;

      &:nth-of-type(2) {
        margin-bottom: 11px;

        @include lg {
          margin-bottom: 16px;
        }
      }
    }
    ul {
      grid-template-columns: none !important;
      padding: 0 !important;
      grid-row-gap: 8px !important;

      @include lg {
        margin: 0 0 16px 0;
      }

      &:first-of-type {
        margin-bottom: 16px;
      }

      &:last-of-type {
        margin: 0;
        padding: 0;
      }
      li {
        margin-left: 22px;
        display: list-item;
        box-shadow: none;
      }
    }
    p:nth-of-type(2) {
      margin-bottom: 16px;
    }
    @include lg {
      .collapse-params__item {
        grid-template-rows: none;
        max-width: 100%;
        align-items: center;
        padding: 0 !important;
        margin-bottom: 16px;

        &:first-of-type {
          padding-top: 16px;
        }
      }
    }
  }

  .collapse-params__icon {
    position: relative;
    display: flex;
    align-items: center;

    img {
      width: auto;
      height: auto;
      margin: 0;
    }

    span {
      font-family: var(--fontSerif);
      font-weight: 400;
      font-size: 18px;
      color: #ff59a3;
      position: absolute;
      transform: translate(-50%, -38%);
      left: 50%;
      top: 50%;
      @include lg {
        transform: translate(-50%, -40%);
      }
    }
  }

  .collapse-params__desc {
    p,
    li {
      font-size: 14px;
    }
    p:nth-of-type(2) {
      @include lg {
        font-size: 16px;
      }
    }
    .collapse-params__item {
      .collapse-params__title {
        @include lg {
          font-size: 16px;
        }
      }
    }
  }

  ul {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    gap: 8px;

    &:last-of-type {
      padding-bottom: 25px;
    }

    li {
      display: flex;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
      border-radius: 4px;
      padding-right: 7px;

      img {
        margin: 0;
        max-width: 70px;
      }

      img.army {
        min-height: 100px;
      }

      p {
        margin: 16px 0 !important;
        font-size: 14px;
      }
    }
  }

  .collapse-params__desc {
    ul {
      display: grid;

      li {
        &:nth-child(3) {
          grid-row-start: 2;
          grid-row-end: 2;
          @include lg {
            grid-row-start: auto;
            grid-row-end: auto;
          }
        }

        &:nth-child(5) {
          grid-row-start: 3;
          grid-row-end: 3;
          @include lg {
            grid-row-start: auto;
            grid-row-end: auto;
          }
        }
      }
    }
  }

  @include lg {
    .join-item:nth-of-type(2) {
      ul {
        @include lg {
          column-gap: 32px;
          row-gap: 16px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto auto;
          padding: 0;
        }
        li:nth-of-type(4) {
          img {
            @include lg {
              max-width: 80px;
            }
          }
        }
      }
    }
    .join-item {
      &:last-child {
        .collapse {
          border-bottom: none;
        }
      }

      &-tag {
        padding: 8px 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-top: 18px;
      }
    }
    .collapse {
      &-head {
        padding: 16px 0 31px 0;

        &__title {
          font-size: 22px;
          line-height: 26px;
        }
      }

      &-body {
        border-bottom: none;
      }

      &-params {
        padding: 24px;

        &__subtitle {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
