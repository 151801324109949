<template>
  <div class="header-fixed">
    <RegionConfirm
      v-if="!region && showRegionConfirm && modalShowRegionConfirm"
      :location="modalShowLocation"
      @show-location="modalShowLocation = true"
    />
    <HeaderNav v-if="breakpointsDesktop.includes(screenSize)" />
    <header class="header">
      <div class="header__inner">
        <router-link class="header__logo" :to="{ name: 'Home' }">
          <svg width="59" height="22" viewBox="0 0 59 22" fill="none">
            <path
              d="M51.0653.0888c2.2077-.3156 4.2264.2152 5.6021 1.509 1.2583 1.1836 1.8931 2.8598 1.7414 4.5992-.2871 3.2877-1.8908 5.922-6.3561 10.2621l-7.3078.2386s5.0904-4.5997 7.005-6.847c1.2406-1.4564 1.7661-3.0284 1.2565-4.0762-.3228-.664-1.0478-.9945-1.9411-.945V.0887Zm-14.975 2.6068v4.274l6.3472-.6945v-4.467l-6.3472.8875ZM30.9212 22V3.4184l4.3553-.609V22h-4.3553ZM13.7167 5.8293v3.5929l4.7976-.5248V5.1587l-4.7976.6706ZM9.7753 21.9999V6.3805l3.3224-.4645V22H9.7753ZM0 7.7472v3.1761l8.6123-.9426V6.5433L0 7.7472Zm2.748 3.3692V22h3.1533V10.7963l-3.1533.3201Zm10.9687 4.6391v-3.659l3.1496-.2693v3.7572l-3.1496.1711Zm0 2.6367v3.6077h4.7976v-3.7517l-4.7976.144Zm22.3736-3.8545v-4.3533l4.1604-.3547v4.4819l-4.1604.2261Zm0 3.1818v4.2804h6.3471V17.529l-6.3471.1905Zm-16.3067 4.2804V4.9811l3.7845-.5292V22h-3.7845Zm4.4909-3.9246v3.9246h5.4938v-4.0895l-5.4938.1649Zm34.3922 3.9246v-4.9607l-14.8233.3668v4.5939h14.8233ZM44.0365 9.4019c-.244-5.1426 1.8706-8.2947 6.1766-9.2066l.0027 4.828c-.9038.3789-1.797 1.068-1.5878 3.9735l-4.5915.4052Z"
              fill="#fff"
            />
          </svg>
        </router-link>
        <div class="header__right">
          <button
            v-show="breakpointsDesktop.includes(screenSize)"
            @click="modalShowLocation = true"
            class="header-location"
          >
            {{ currentRegion || "Москва и область" }}
          </button>
          <button @click="modalShowNotify = true" class="header__notify">
            <div class="bell__wrapper">
              <Bell :has-new-nots="hasMessages" />
            </div>
          </button>
          <button
            v-if="modalShowNav"
            v-show="breakpointsMobile.includes(screenSize)"
            @click="modalShowNav = false"
            class="header__close"
          ></button>
          <button
            v-else
            v-show="breakpointsMobile.includes(screenSize)"
            @click="modalShowNav = true"
            class="header__menu"
          ></button>
          <ModalMessage
            v-if="modalShowMessage"
            text="Добавили новый тариф «Мой Онлайн+» в раздел «Тарифные планы»"
            to="/"
            @close="onCloseMessage"
          />
        </div>
      </div>
    </header>
  </div>
  <AsyncModal v-if="modalShow" @close="onClose" />
  <ModalNav
    v-if="modalShowNav"
    :location="modalShowLocation"
    :current="currentRegion"
    @close="onCloseNav"
    @show-location="modalShowLocation = true"
  />
  <ModalNotify v-if="modalShowNotify" @close="onCloseNotify" />
  <RegionConfirmModal
    v-if="!region && !modalShowRegionConfirm"
    @close="onCloseModalRegionConfirm"
    @changeRegion="openLocationModal"
  />
  <ModalLocation
    v-if="modalShowLocation"
    @close="onCloseLocation"
    :current="currentRegion"
  />
</template>

<script>
import { mapState } from "vuex";
import { defineAsyncComponent } from "vue";
import ModalNav from "@/components/ModalNav.vue";
import ModalNotify from "@/components/ModalNotify.vue";
import ModalLocation from "@/components/ModalLocation.vue";
import RegionConfirm from "@/components/RegionConfirm.vue";
import HeaderNav from "./HeaderNav.vue";
import ModalMessage from "./ModalMessage.vue";
import { regions } from "@/constants";
import Bell from "@/components/Bell";
import store from "@/store";
import RegionConfirmModal from "@/components/RegionConfirmModal.vue";

export default {
  name: "HeaderComponent",
  components: {
    Bell,
    AsyncModal: defineAsyncComponent(() =>
      import("@/components/ModalSearch.vue")
    ),
    ModalNav,
    ModalNotify,
    ModalLocation,
    RegionConfirm,
    HeaderNav,
    ModalMessage,
    RegionConfirmModal,
  },
  data() {
    return {
      modalShow: false,
      modalShowNav: false,
      modalShowNotify: false,
      modalShowLocation: false,
      modalShowMessage: false,
      modalShowRegionConfirm: false,
      showRegionConfirm: true,
      regions,
    };
  },
  computed: {
    ...mapState({
      region: (state) => state.region.region,
      regionConfirm: (state) => state.api.regionConfirm,
      hasMessages: (state) => state.notifications.hasNewNotifications,
    }),
    currentRegion() {
      return this.regions[this.region]?.name || this.regionConfirm?.name;
    },
  },
  methods: {
    onClose() {
      this.modalShow = false;
    },
    onCloseNav() {
      this.modalShowNav = false;
    },
    onCloseNotify() {
      this.modalShowNotify = false;
      store.commit("notifications/setHasNots", false);
    },
    onCloseLocation() {
      this.modalShowLocation = false;
    },
    onCloseMessage() {
      this.modalShowMessage = false;
    },
    onCloseModalRegionConfirm() {
      this.modalShowRegionConfirm = true;
    },
    openLocationModal() {
      this.modalShowRegionConfirm = true;
      this.modalShowLocation = true;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  --height: 46px;
  height: var(--height);
  background: var(--colorDarkSecond);
  display: grid;
  place-items: center;
  padding: 0 var(--widthGutter);
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    position: relative;
    top: 2px;
    z-index: 3010;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    a {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 0;
      @include md {
        margin-right: 34px;
      }
    }
  }
  &__notify {
    @include reset-button;
    width: 45px;
    height: 45px;
    display: grid;
    place-items: center;
  }
  &__menu {
    @include reset-button;
    width: 45px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 1.5c0-.5523.4477-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Zm0 6c0-.5523.4477-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Zm1 5c-.5523 0-1 .4477-1 1s.4477 1 1 1h16c.5523 0 1-.4477 1-1s-.4477-1-1-1H1Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: -4px;
    margin-right: -13px;
  }
  &__close {
    @include reset-button;
    width: 45px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: -4px;
    margin-right: -13px;
    // position: relative;
    position: fixed;
    top: 0;
    z-index: 3010;
  }
  &-location {
    @include reset-button;
    font-size: 16px;
    line-height: 26px;
    color: #7c8792;
    width: 100%;
    text-align: left;
    padding-left: 28px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 9.75c0 1.8873-.59 3.5289-1.8026 5.413-1.1331 1.7608-2.7777 3.6866-4.9474 6.1918-2.1697-2.5052-3.8143-4.431-4.9474-6.1918C5.84 13.2789 5.25 11.6373 5.25 9.75 5.25 6.022 8.272 3 12 3c3.7279 0 6.75 3.022 6.75 6.75Z' stroke='%23959597' stroke-width='1.5'/%3E%3Ccircle cx='12' cy='9.75' r='3' stroke='%237C8792' stroke-width='1.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}
</style>
