<template>
  <div class="home">
    <div class="home-inner">
      <div class="home-section">
        <div class="home-section__inner">
          <SectionPromo />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionNavigation />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionAdvantages />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionTariffs />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionOffers />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionBonuses />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionApps />
        </div>
      </div>
      <!-- <div class="home-section">
        <div class="home-section__inner">
          <SectionPromoTariff />
        </div>
      </div> -->
      <div class="home-section">
        <div class="home-section__inner">
          <SectionTechnology />
        </div>
      </div>
      <!-- <div class="home-section">
        <div class="home-section__inner">
          <SectionSelfReg />
        </div>
      </div> -->
      <div class="home-section">
        <div class="home-section__inner">
          <SectionJoin />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionPersonal />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section__inner">
          <SectionMap />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionPromo from "@/components/SectionPromo.vue";
import SectionNavigation from "@/components/SectionNavigation.vue";
import SectionAdvantages from "@/components/SectionAdvantages.vue";
import SectionTariffs from "@/components/SectionTariffs.vue";
import SectionOffers from "@/components/SectionOffers.vue";
// import SectionPromoTariff from "@/components/SectionPromoTariff.vue";
import SectionTechnology from "@/components/SectionTechnology.vue";
// import SectionSelfReg from "@/components/SectionSelfReg.vue";
import SectionJoin from "@/components/SectionJoin.vue";
import SectionMap from "@/components/SectionMap.vue";
import SectionBonuses from "@/components/SectionBonuses.vue";
import SectionApps from "@/components/SectionApps.vue";
import SectionPersonal from "@/components/SectionPersonal.vue";

export default {
  name: "HomeView",
  components: {
    SectionPromo,
    SectionNavigation,
    SectionAdvantages,
    SectionTariffs,
    SectionOffers,
    // SectionPromoTariff,
    SectionTechnology,
    // SectionSelfReg,
    SectionJoin,
    SectionMap,
    SectionBonuses,
    SectionApps,
    SectionPersonal,
  },
};
</script>

<style scoped lang="scss">
.home {
  padding-top: 45px;
  @include lg {
    padding-top: 91px;
  }
}
</style>
